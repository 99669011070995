// more-resource styles
.more-resource__wrapper {
  @media screen and (max-width: 47.9375em) {
    .more-resource {
      margin: 0 0 15%;
    }

    .row {
      padding: 0 0.4375em;
    }

    .col-4 {
      width: 50%;
    }
  }

  @media only screen and (max-width: 479px){
    &--special {
      .col-4 {
        width: 100%;
      }
    }
  }
}