// exhibition-item styles
.exhibition {
  &:hover {
    img {
      transform: scale(1.2);
    }
    .exhibition__img {
      &:before {
        opacity: 1;
      }
    }
  }

  &__img {
    overflow: hidden;
    position: relative;
    &:before {
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      z-index: 2;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  img {
    width: 100%;
    display: block;
    transition: all 0.8s ease-in-out;
  }

  @media screen and (max-width: 767px) {
    .exhibition__time{
      font-size: 15px;
    }
    .exhibition__title{
      font-size: 0.70625em;
      margin-top: 1.42857em;
      margin-bottom: 0.48544em; 
    }
    .exhibition__text-title{
      font-size: 0.70625em;
    }
    .exhibition__text{
      font-size:0.65625em;
    }
    .exhibition__status{
      font-size:0.65625em;
    }
    .exhibition__info{
      display: none;
    }
    
    &:after{
      display: none;
    }
  }
}
.exhibition__title {
  height: auto;
  overflow: visible;
}
