// timeline styles
.timeline {
    overflow: hidden;
    width: 100%;

    h2 {
        font-family: 'GT-Walsheim-Black';
        font-size: calc-em(36px);
        text-align: center;
        line-height: 1;
        margin: 81px 0 30px;
    }

    .slick-arrow {
        position: absolute;
        top: 50%;
        outline: 0;
        border: none;
        background-color: rgba(0,0,0,0.2);
        border-radius: 50%;
        width: 50px;
        height: 50px;
        z-index: 1;
        cursor: pointer;

        i {
            display: inline-block;
            position: absolute;
            top: 16px;
            left: 16px;
            color: #fff;
            font-size: 18px;
        }

        &.slick-prev {
            left: 0;
            transform: translate(0, -50%);
        }

        &.slick-next {
            right: 0;
            transform: translate(0, -50%);
        }
    }

    &__nav {
        position: relative;
        width: 902px;
        height: 30px;
        margin: 55px auto 75px;
        overflow: visible;

        &--line {
            width: 100%;
            border-top: 2px dotted $sam-blue;
            // border-image-source: url(../img/dotted.svg);
            border-image-slice: 33%;
            border-image-repeat: round;
            position: absolute;
            top: 14px;
        }

        &--linefill {
            height: 2px;
            background-color: $sam-blue;
            position: absolute;
            top: 14px;
            transition: width 0.5s;
            transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
        }

        &--years {
            span {
                font-family: 'GT-Walsheim-Black';
                font-size: 16px;
                color: #000;
            }
        }

        ul {
            @extend %clearfix;
            width: 100%;
            height: 1px;
            position: relative;
        }

        li {
            @extend %clearfix;
            display: inline-block;
            position: absolute;
            left: 0;

            .year {
                position: absolute;
                left: 0;
                transform: translate(-50%, 0);
                font-family: 'GT-Walsheim-Black';
                font-size: 16px;
                color: #000;
                transition: all 0.3s;
            }

            &:nth-child(even) {
                .year {
                    top: 100%;
                }
            }

            &:nth-child(odd) {
                .year {
                    bottom: 100%;
                }
            }

            &:last-child {
                margin: 0;
            }

            &.active {
                .year {
                    color: $sam-pink;
                }
            }
        }

        .timeline__nav--dots {
            float: left;
            display: inline-block;
            width: 30px;
            height: 30px;
            padding: 0 10px;
            border-radius: 50%;
            position: relative;
            transform: translate(-15px, 0);
            cursor: pointer;

            &:before {
                content: '';
                display: inline-block;
                border: 1px solid transparent;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%) scale(0);
                transition: all 0.3s;
            }

            &.active {
                &:before {
                    border: 1px solid $sam-red;
                    transform: translate(0, -50%) scale(1);
                }

                .dot {
                    background-color: $sam-red;
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .dot {
            display: inline-block;
            border: none;
            background-color: $sam-blue;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            outline: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s;
            cursor: pointer;
        }
    }
}

// // to 767px
@media screen and (min-width: 64em) {
    .timeline {
        .slick-list {
            overflow: visible;
        }
    }
}

@media screen and (max-width: 63.9375em) {
    .timeline {
        .container {
            overflow: visible;
            width: 100%;
            padding: 0;
        }

        &__nav {
            &--responsive {
                overflow: hidden;
                padding: 0 30px;

                &::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 0px;
                    height: 0px;
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 47.9375em) {
    .timeline {
        h2 {
            font-size: calc-em(24px);
            margin: 50px 0 25px;
        }
    }
}
