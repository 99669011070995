//Import helpers
@import "./_variables";
@import "./_mixins";
@import "./_helpers";
@import "./_icons";
@import "./_grids";

// Normalize Styles
@import "node_modules/normalize.css/normalize";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

//Plugin Styles
// @import "node_modules/slick-carousel/slick/slick.scss";

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.yeogurt-info {
  text-align: center;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.version {
  font-size: 14px;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.logo {
  margin-top: 15px;
}

figure {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}

body {
  margin-top: 106px;
  height: auto !important;
}

.no-space-below {
  margin-bottom: 0 !important;
}

// Residencies Template
.time-of-other {
  .list-detail--cta {
    margin: 0 0 1.5em;
    text-align: left;
    margin-left: -20px;

    .btn--cta {
      border: none;
      font-size: calc-em(15px);
      color: #000;
      font-weight: 700;
      line-height: 1.3;

      &:hover {
        border: none;
        color: #e80262;
      }

      .arrow-ico {
        display: inline-block;
        transform: rotate(180deg);
        margin: 0 10px 0 0;
        height: 1.35em;
      }
    }
  }
}

.list-tagline.residencies {
  background-color: transparent;
}

.list-tagline.residencies .tagline-item {
  color: #e80262;

  @include mobile {
    margin: 0 0 2em;
  }
}

// .residencies-listing span.month__title {
//   font-size: .65em;
//   padding-right: 1.5em;
//   text-align: center;
// }

.residencies-listing {
  @include mobile {
    .event-calendar__title {
      line-height: 1.2;
    }

    .slider-events {
      margin: 1.50463em 0 2.02546em 0;
    }

    .slider-events__item {
      margin-left: 0;
    }

    .slider-events__link {
      padding: 0 40px;

      .month__title {
        display: inline-block;
      }
    }
  }
}

@media screen and (min-width: 40em) and (max-width: 79.9375em) {
  body {
    margin-top: 88px;
  }
}

@media screen and (max-width: 39.9375em) {
  body {
    margin-top: 56px;
  }
}

// Import Modules

// Utils
@import "../_modules/subscribe-validation/subscribe-validation";
@import "../_modules/utils/section/section";
@import "../_modules/utils/select-clone/select-clone";
@import "../_modules/utils/table-responsive/table-responsive";
@import "../_modules/utils/back-to-top/back-to-top";

// Atoms
// @import "../_modules/atoms/button/button";
@import "../_modules/atoms/custom-checkbox/custom-checkbox";
@import "../_modules/atoms/exhibition-item/exhibition-item";
@import "../_modules/atoms/back-button/back-button";

// Molecules
@import "../_modules/molecules/breadcrumb/breadcrumb";
@import "../_modules/molecules/side-nav/side-nav";
@import "../_modules/molecules/blog-filter/blog-filter";
@import "../_modules/molecules/blog-tile/blog-tile";
@import "../_modules/molecules/pagination/pagination";
@import "../_modules/molecules/resource-card/resource-card";
@import "../_modules/molecules/timeline-item/timeline-item";
@import "../_modules/molecules/associated-events/associated-events";
@import "../_modules/molecules/maintenance/maintenance";
@import "../_modules/molecules/media-component/media-component";
@import "../_modules/molecules/exhibition-programme/exhibition-programme"; 

// Organisms

@import "../_modules/organisms/site-header/site-header";
@import "../_modules/organisms/notification/notification";
@import "../_modules/organisms/mega-menu-item/mega-menu-item";
@import "../_modules/organisms/site-footer/site-footer";
@import "../_modules/organisms/rte/rte";
@import "../_modules/organisms/mobile-nav/mobile-nav";
@import "../_modules/organisms/banner/banner";
@import "../_modules/organisms/blog-banner/blog-banner";
@import "../_modules/organisms/highlight-work/highlight-work";
@import "../_modules/organisms/collection/collection";
@import "../_modules/organisms/other-exhibitions/other-exhibitions";
@import "../_modules/organisms/timeline/timeline";
@import "../_modules/organisms/direction-tabs/direction-tabs";
@import "../_modules/organisms/search-module/search-module";
@import "../_modules/organisms/more-resource/more-resource";
@import "../_modules/organisms/exhibition/exhibition"; 
@import "../_modules/organisms/exhibition-anchor/exhibition-anchor"; 
@import "../_modules/organisms/selected-artwork/selected-artwork"; 
@import "../_modules/organisms/exhibition-banner/exhibition-banner"; 
@import "../_modules/organisms/exhibition-widget/exhibition-widget"; 
@import "../_modules/organisms/popup-highlighted-works/popup-highlighted-works"; 

// Residencies Template
// Atoms
@import "../_modules/atoms/residents-card/residents-card";

// Molecules
@import "../_modules/molecules/intro/intro";
@import "../_modules/molecules/read-more/read-more";
@import "../_modules/molecules/residents-cta/residents-cta";

// Organisms
@import "../_modules/organisms/feature-residents/feature-residents";
@import "../_modules/organisms/feature-residents-news/feature-residents-news";

.list-detail--cta .btn--cta:before,
.list-detail--cta .btn--cta:after {
  content: none;
}

.time-of-other__credits {
  display: flex;
  justify-content: space-between;

  .image-credit {
    font-size: calc-em(16px);
  }
}

.custom-quote {
  padding: 20px 0 20px 0px;
  position: relative;
  margin: 20px 0;
  border-top: 1px solid #9b9b9b;
  border-bottom: 1px solid #9b9b9b;

  &:before {
    // content: "❝";
    position: absolute;
    top: 10px;
    left: 0;
    font-size: calc-em(80px);
    color: $sam-pink;
  }

  .text {
    font-size: 14px;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-style: italic;
    font-weight: 400;
  }

  cite {
    display: block;
    color: $sam-pink;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    margin-top: 20px;
    font-family: "Open Sans", Helvetica, sans-serif;
  }
}

// Collection carousel
.collection_carousel_sec {
  margin-bottom: 40px;
  
  .collection_carousel {
    position: relative;
    .collection_item {
      border: 1px solid #c4c4c4;
      display: flex !important;
      &[url]{
        cursor: pointer;
      }
      .img_sec {
        width: 30%;
        float: left;
      }
      .collection_content {
        width: 70%;
        float: left;
        padding: 48px;
        position: relative;

        .collection_title {
          font-family: "GT-Walsheim-Black";
          font-style: normal;
          font-weight: 900;
          font-size: 19.6875px;
          padding-bottom: 12px;
          color: #000000;
        }

        .collection_desc {
          font-family: "roboto-light";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          color: #4c4c4c;
          padding-bottom: 16px;
        }

        .collection_period {
          position: absolute;
          bottom: 30px;
          .collection_year {
            font-style: normal;
            font-weight: 800;
            font-size: 14px;
            span {
              font-family: "roboto-light";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              color: #979797;
              padding-left: 8px;
              padding-bottom: 8px;
            }
          }

          .collection_category {
            font-family: "roboto-light";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #e80262;
          }
        }
      }
    }
    .slick-arrow {
      position: absolute;
      top: 50%;
      outline: 0;
      border: none;
      background-color: transparent;
      border: 2px solid #000000;
      border-radius: 50%;
      width: 29px;
      height: 29px;
      z-index: 1;
      cursor: pointer;
      &.slick-prev {
        left: -40px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
      &.slick-next {
        right: -40px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
      }
    }
    .slick-dots {
      position: absolute;
      bottom: -25px;
      left: 25px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
      li {
        position: relative;
        display: inline-block;
        width: 8%;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        &::after {
          content: "";
          display: block;
          width: 60%;
          margin-top: -12px;
          border: 2px dashed #000;
          margin-left: 24px;
        }

        button {
          font-size: 0;
          line-height: 0;
          display: block;
          width: 20px;
          height: 20px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: none;
          opacity: 0.4;
          // border-radius: 50%;
          outline: none;
          background: transparent;
          &::before {
            font-family: "slick";
            font-size: 38px;
            line-height: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            content: "•";
            text-align: center;
            color: #000;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
          }
        }
      }

      li:last-child {
        &::after {
          content: "";
          border: 0;
        }
      }

      .slick-active {
        button {
          width: 20px;
          height: 20px;
          padding: 5px;
          cursor: pointer;
          color: transparent;
          border: 2px solid #000;
          border-radius: 50%;
          outline: none;
          background: transparent;
          opacity: 1;
        }
      }
    }
  }
}
@media only screen and (max-width: $bpLgMobile) {
  .collection_carousel_sec {
    .collection_carousel {
      .collection_item {
        display: inline-block !important;
        .img_sec {
          width: 100%;
          border: 1px solid #c4c4c4;
        }
        .collection_content {
          width: 100%;
          padding: 10px;
          position: relative;
          border: 1px solid #c4c4c4;

          .collection_period {
            position: static;
            bottom: 8px;
            .collection_year {
              font-style: normal;
              font-weight: 800;
              font-size: 14px;
            }
          }
        }
      }

      .slick-dots {
        position: absolute;
        bottom: -42px;
        left: 0;
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
        li {
          position: relative;
          display: inline-block;
          width: 8%;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;

          &::after {
            content: "";
            display: block;
            width: 58%;
            margin-top: -12px;
            border: 2px dashed #000;
            margin-left: 20px;
          }

          button {
            font-size: 0;
            line-height: 0;
            display: block;
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: none;
            opacity: 0.4;
            // border-radius: 50%;
            outline: none;
            background: transparent;
            &::before {
              font-family: "slick";
              font-size: 38px;
              line-height: 20px;
              position: absolute;
              top: 0;
              left: 0;
              width: 20px;
              height: 20px;
              content: "•";
              text-align: center;
              color: #000;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
        }

        li:last-child {
          &::after {
            content: "";
            border: 0;
          }
        }

        .slick-active {
          button {
            width: 20px;
            height: 20px;
            padding: 5px;
            cursor: pointer;
            color: transparent;
            border: 2px solid #000;
            border-radius: 50%;
            outline: none;
            background: transparent;
            opacity: 1;
          }
        }
      }
    }
  }
}

// blog filter

.blog_module {
  padding-bottom: 50px;
  padding-top: 20px;
  .events-filter {
    max-width: 100%;
  }
}

.blog_module .events-filter__display,
.blog_module .events-filter__type {
  width: auto;
}
.blog_module .events-filter__type {
  margin-right: 0.8125em;
}
.blog_module .events-filter .ico {
  color: #a4a4a4;
}
.blog_module .events-filter .ico-grid {
  font-weight: bold;
}
.blog_module .events-filter__item:first-child {
  margin-right: 1em;
}
.blog_module .events-filter__item.active .ico {
  color: #000000;
}
.blog_module__item {
  margin-bottom: 1.125em;
}
.blog_module .more-resource {
  margin-bottom: 3.4375em;
}
.blog_module .events-filter__display--sort {
  position: relative;
  margin-right: 1.38889em;
}
.blog_module .events-filter__display--sort .events-filter__label {
  padding-right: 0.34722em;
}
.blog_module .events-filter__display--sort:after {
  content: "";
  display: inline-block;
  // background-color: #000000;
  // width: 1px;
  // height: 1.09954em;
  position: absolute;
  right: -0.34722em;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.blog_module .events-filter__display--sort .custom-select {
  width: 14.34259em;
}
.blog_module .events-filter__display--collection .events-filter__label {
  padding-right: 1.04167em;
}
.blog_module .events-filter__display--collection .custom-select {
  width: 10em;
}

.blog_module .events-filter .custom-select::after {
  content: "";
  width: 99%;
  border-bottom: none;
}
.blog_module .result__pagination {
  margin: 0 5.1875em;
}
.blog_module__revert .events-filter__display--sort::after {
  display: none;
}

.events-filter__display {
  .custom-select__options {
    width: 100%;

    &.is-active {
      display: table;
    }

    ul {
      position: relative;
      padding-right: 20px;

      li {
        white-space: nowrap;
      }
    }
  }
}

@media only screen and (min-width: $bpLgDesktop) {
  .blog_module .events-filter__display--sort {
    position: relative;
    left: 34%;
  }
}

@media screen and (max-width: 47.9375em) {
  .events-filter__display {
    padding: 0 0.625em;
  }
  .blog_module .events-filter {
    padding-top: 1.6875em;
    padding-bottom: 0.875em;
    position: relative;
  }
  .blog_module .events-filter:after {
    height: 0.3125em;
  }
  .blog_module .events-filter__display {
    width: 100%;
  }
  .blog_module .events-filter__display--sort {
    margin-right: 0;
  }
  .blog_module .events-filter__display--sort:after {
    display: none;
  }
  .blog_module .events-filter__display--collection {
    padding-left: 1.1875em;
  }
  .blog_module .events-filter__label {
    font-size: 0.75231em;
    line-height: 0.75em;
    margin-bottom: 0.83333em;
  }
  .blog_module .events-filter .custom-select {
    font-size: 0.8125em;
  }
  .blog_module .events-filter .custom-select__options {
    margin-top: 1.41667em;
  }
  .blog_module .events-filter__type {
    position: absolute;
    top: -1.5em;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .blog_module .events-filter .ico {
    font-size: 2.1875em;
  }
  .blog_module .events-filter__item:first-child {
    border-right: 0.03125em solid #979797;
    padding-right: 1.3125em;
    margin-right: 0;
  }
  .blog_module .events-filter__item:nth-child(2) {
    padding-left: 1.25em;
  }
  .blog_module.blog_module--list-view .blog_module__top-bar {
    margin-bottom: 2em;
  }
  .blog_module.blog_module--list-view .col-4 {
    width: 100%;
  }
  .blog_module.blog_module--list-view .card {
    padding: 0;
    margin-bottom: 2.0625em;
  }
  .blog_module.blog_module--list-view .card__name,
  .blog_module.blog_module--list-view .card__title,
  .blog_module.blog_module--list-view .card__date {
    padding: 0;
  }
  .blog_module.blog_module--list-view .card__name {
    font-size: 1.1em;
    height: auto;
  }
  .blog_module.blog_module--list-view .card__title {
    font-size: 0.9375em;
    margin-bottom: 0.73333em;
    height: auto;
  }
  .blog_module.blog_module--list-view .card__date {
    font-size: 0.875em;
  }
  .blog_module .events-filter__display--sort .custom-select {
    width: 100%;
  }
  .blog_module .events-filter__control {
    margin: 0;
  }
}

.news-detail-item {
  border-bottom: 1px solid #9b9b9b;
  padding-top: 1.625em;
  padding-bottom: 1.625em;
  text-align: center;
}

.news-detail-item__content,
.news-detail-item__link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 0.3125em;
  padding-right: 2.5em;
}

@media only screen and (max-width: $bpLgMobile) {
  .news-detail-item__content,
  .news-detail-item__link {
    display: flex;
    align-items: center;
    justify-content: left;
  }
}
.news-detail-item__link {
  color: #000000;
  text-decoration: none;
}
.news-detail-item__text {
  display: inline-block;
  padding-left: 0.83333em;
  color: #000000;
  font-family: "roboto-bold";
  font-size: 0.9em;
  letter-spacing: 0;
  line-height: 1.2em;
}
.news-detail-item__text--light {
  color: #4c4c4c;
  font-family: "roboto-light";
  line-height: 1.5em;
}
.news-detail-item__ico::before {
  font-family: "sam";
  font-size: 1.9375em;
}
.news-detail-item--calendar .news-detail-item__ico::before {
  content: "";
}

.time-of-other__right {
  border: none;
}

.custom-template-form{
  margin-bottom: 6.4375em;
  .description{
    font: 0.9375em/1.13333em "roboto-medium";
    color: #ffffff;
    margin-bottom: 2.33333em;
    max-width: 21em;
  }
  .title{
    font-size: 2.375em;
    line-height: 1.36842em;
    letter-spacing: 0.07895em;
    margin-bottom: 2.47368em;
    font: 2.1875em "GT-Walsheim-Black";
    color: #000000;
  }
  .text-box {
    font-size: 0.875em;
    border: none;
    width: 100%;
    padding-top: 0.71429em;
    padding-bottom: 0.71429em;
    border-bottom: 1px solid #9b9b9b;
    outline: none;
    z-index: 2;
    background-color: transparent;
    transition: all .3s; 
    &:focus{
      border-color: #007bff;
    }
    &:focus ~ label {
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
  }
  .row-field{
    display: flex;
    .field-content{
      flex: 1;
      &:first-child{
        margin-right: 0.75em;
      }
      &:last-child{
        margin-left: 0.75em;
      }
    }
  }
.label-input {
  font: 0.8125em "roboto-light";
  color: #979797;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  transition: -webkit-transform .5s;
  transition: transform .5s;
  transition: transform .5s, -webkit-transform .5s; 
  &.active{
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.input-validation-error {
  border-color: #e20039; }
.control-label {
  font: 0.875em "roboto-medium";
  color: #979797;
  display: none; }
.checkbox-custom{
  label{
    &::before{
      width: 15px;
      height: 15px;
    }
    &::after{
      top: 7px;
      left: 3px;
      width: 9px;
      height: 9px;
    }
  }
 input:hover + label:before{
  border-color:#9b9b9b ;
 }
 input:checked + label:before{
  border-color:#000000;
 }
 input:hover + label:after{
  background-color: transparent;
 }
 input:checked + label:after{
  background-color: #000000;
 }
}
.list-items{
  &::before{
    content: ' ';
    display: table;
  }
  &::after{
    content: ' ';
    display: table;
    clear: both;
  }
  .control-label{
    display: block;
    margin-bottom: 2.17857em;
  }
  .item{
    width: 50%;
    margin-bottom: 0.71429em;
    float: left;
    @media only screen and (min-width: 48em){
      width: 33.33333%;
    }
  }
}
.radio {
  float: left;
  padding-right: 0.625em;
    input{
      position: absolute;
      left: -9999px; 
      &:not(:checked) ~ label:after{
        opacity: 0; 
      }
      &:checked ~ label {
        color: #4a4a4a; 
      &::after{
        opacity: 1;
        }
      }
    } 
    label {
      font: 0.75em "roboto-light";
      cursor: pointer;
      color: #979797;
      padding-left: 1.5625em;
      position: relative;
      display: block; 
      :focus{
        outline: none;
      }
      &:before,&:after{
        position: absolute;
        font-size: 1em;
        color: #979797;
        top: 0.14286em;
        left: 0;
      }
      &:before{
        content: "";
      }
      &:after{
        content: "";
      }
    }
    }
    .form-group {
      clear: none;
      // width: 50%;
      z-index: 2;
      position: relative;
      margin-bottom: 1.42857em; 
    }
    .multiline{
      width: 100%;
      position: relative;
      border: 1px solid #9b9b9b;
      @media only screen and (min-width: 48em) {
        margin-bottom: 1.75em;
        min-height: 11.73333em;
      }
      &.input-validation-error{
        border: 1px solid #e20039;
      }
      &.custom-textarea__focus{
        border-color: #007bff;
      }
      .control-label{
        display: none !important;
      }
    }
    .has-error{
      .multiline{
        border-color: #e20039;
        &.custom-textarea__focus{
          border-color: #007bff; 
        }
      }
    }
    .form-control{
      font-family: "roboto-light";
      font-size: 0.9375em;
      width: 100%;
      resize: none;
      padding: 2em 1.33333em 1em;
      border: none; 
      &:focus{
        outline: none; 
      }
    }
    .sum-character{
      font: 0.875em/1 "roboto-light";
      color: #979797;
      position: absolute;
      bottom: 0.71429em;
      left: 1.42857em;
      display: none;
    }
    .input-has-value .sum-character{
      display: block;
    }
    .label-multiline{
      font: 0.875em/1 "roboto-light";
      color: #979797;
      position: absolute;
      top: 0.71429em;
      left: 1.42857em;
    }
    .custom-note{
      font: 0.75em/1.41667em "roboto-light";
      color: #9b9b9b;
      font-style: italic;
      .custom-bold{
          font-family: "roboto-bold";
          padding-right: 0.25em; }
      }
    .btn--cta{
      display: inline-block;
      padding-bottom: 0.0625em;
      .form-submit-border{
        .btn{
          min-width: 12.84722em;
          border: none;
          padding: 0.75em;
        }
      }
    }
    .button-success__btn{
      font: 1.125em "roboto-bold";
      min-width: 17.22222em;
      padding: 0;
      margin: 1.38889em auto 0;
      &.btn--cta .btn__text {
        padding-top: 0.5787em;
        padding-bottom: 0.86806em;
      }
    }
     .validation-summary-errors, .field-validation-error, .field-error {
      display: none; 
     }
     .g-recaptcha ~ .field-validation-error {
      display: block !important;
      font: 0.75em "roboto-light";
      color: #ff0000; 
    }
    .bg-warning {
      display: none; 
    }
    .custom-radio-list{
      width: 100%;
    }

    &.theme-2{
      .row-field{
        .field-content{
          margin: 0;
        }
        .list-items{
          display: flex;
          align-items: center;
          .control-label{
            min-width: 150px;
            margin: 10px;
          }
        }
        .btn--cta{
          .form-submit-border{
            width: 100%;
            height: 100%;
            .btn{
              width: 100%;
              height: 100%;
              background: transparent;
              &:hover{
                background: white;
              }
            }
          }
        }
      
      }
      input{
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #4a4a4a;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #4a4a4a;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: #4a4a4a;
        }
      }
      textarea{
        background: transparent;
      }
      .label-multiline{
        color: #ffffff;
      }
      .text-box {
        font: 0.875em "roboto-medium";
        color: #ffffff;
        letter-spacing: .5px;
        background-color: transparent;
        border: none;
        width: 100%;
        height: 100%;
        padding: 1.42857em 1.85714em;
        display: block;
      }
      .field-content{
        border: 0.125em solid #ffffff;
        padding: 0.125em;
      }
      .control-label{
        color: #ffffff;
      }
      .label-input{
        display: none;
      }
      .radio label{
        color: #000000 !important;
      }
    }

    .flex-1{
      flex:1 !important; 
    }
    .flex-2{
      flex:2 !important; 
    }
    .flex-3{
      flex:3 !important; 
    }
    .flex-4{
      flex:4 !important; 
    }
    .flex-5{
      flex:5 !important; 
    }
  }
//   .contact-us .form-group.sam-singleline-half_Label {
//     padding-right: 0.75em;
//     float: left; }
//     .contact-us .form-group.sam-singleline-half_Label ~ .sam-singleline-half_Label {
//       padding-left: 0.75em;
//       padding-right: 0; }
//       .contact-us .form-group.sam-singleline-half_Label ~ .sam-singleline-half_Label .label-input {
//         left: 0.92308em; }
//   .contact-us .form-group.sam-Contact_Email {
//     width: 100%;
//     clear: left; }


.contact-us__required {
  font: 0.875em/1 "roboto-light";
  color: #4c4c4c;
  display: block;
  width: 100%;
  text-align: right;
  margin-bottom: 1.57143em; }
  .contact-us__required.has-error {
    color: #ff0000; }

.popup-highlight {
  &__title {
    top: -1.3em;
    left: 0;
  }
}

.events-filter .custom-select__styled:after {
  content: "\e956"
}