// residents-card styles
@keyframes scale {
  100% {
    // transform-origin: center center;
    transform: scale(1.1);
  }
}

.residents {
  $p: &;

  display: block;
  position: relative;
  margin-bottom: 4em;
  padding-bottom: 1em;
  text-align: center;
  text-decoration: none;
  z-index: 1;

  &:hover {
    opacity: .7;
  }

  &__img {
    display: block;
    width: 100%;
  }

  @include mobile {
    margin-bottom: 3em;
  }

  .border {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // margin-top: 12.5%;
    border-left: #9b9b9b 0.0625em solid;
    border-right: #9b9b9b 0.0625em solid;
    border-top: #9b9b9b 0.0625em solid;
    pointer-events: none; 

    &__top,
    &__bottom {
      position: absolute;
      width: 100%;
      height: 12%;
    }

    &__top:before, 
    &__top:after, 
    &__bottom:before, 
    &__bottom:after {
      content: '';
      position: absolute;
      width: 50%;
      height: 100%; 
    }

    &__top {
      top: 0; 
    }

    &__top:before, 
    &__top:after {
      border-top: #9b9b9b 0.0625em solid;
    }

    &__top:before {
      left: 0;
      padding-right: 1px;
      transform: skewY(-13.5deg);
      transform-origin: 0 0; 
    }

    &__top:after {
      right: 0;
      transform: skewY(13.5deg);
      transform-origin: 100% 0; 
    }

    &__bottom {
      bottom: 1px;
    }

    &__bottom:before, 
    &__bottom:after {
      border-bottom: #9b9b9b 0.0625em solid; 
    }

    &__bottom:before {
      left: 0;
      padding-right: 1px;
      transform: skewY(13.5deg);
      transform-origin: 0 0; 
    }

    &__bottom:after {
      right: 0;
      transform: skewY(-13.5deg);
      transform-origin: 100% 0;
    }
  }  

  &:hover {
    #{$p}__overlay {
      opacity: .2; 
    }

    .residents__svg-image {
      // animation: scale 1.2s ease-in-out both;
      // animation-iteration-count: 1;
    }
  }

  &__overlay {
    fill: #000000;
    opacity: 0;
    transition: opacity .3s ease; 
  }

  &-item__text {
    // padding: 1.5em 0 2em;
    padding: 1.5em 0 0;
  }
  
  &--no-location {
    #{$p}__status {
      margin-bottom: 1.2054em; 
    }
  } 

  &--no-location:after {
    display: none; 
  }
  
  &__wrapper {
    margin-bottom: 9.375em;
    text-align: center;
    position: relative;
    z-index: 1; 
    
    .container {
      margin-top: 4.375em; 
    }

    .title__wrapper {
      margin-bottom: 0.125em; 
    }

    &--learning {
      .container {
        margin-top: 0;
      }
    }

    &__past {
      font: 0.9em/0.83333em "roboto-bold";
      letter-spacing: 0.01736em;
      color: #9b9b9b;
      position: relative;
      z-index: 1;
      text-decoration: none;
      margin-left: 1.04167em;

      &:hover {
        opacity: .7; 
      }

      &__arrow-ico {
        height: 1.04167em;
        margin-left: 0.27778em;
        vertical-align: middle;
        line-height: 1.11111em; 
      }
    }
  }

  &__title, 
  &__status, 
  &__info {
    display: block;
    margin-left: 1.06075em;
    margin-right: 1.06075em;
  }
  
  &__title, 
  &__status {
    color: #000000; 
  }

  &__date {
    text-transform: uppercase;
    font-family: "roboto-light";
    font-size: 1.25em;
    color: #000;
  }
  
  &__title {
    font: 1.29625em/1.14609em "GT-Walsheim-Black";
    letter-spacing: -0.02122em;
    // height: 3.43828em;
    overflow: hidden;
    // margin-top: 0.24108em;
    // margin-top: .5em;
    margin: 0.72324em 1.2054em 0;
    padding-bottom: 0.9161em;
    border-bottom: #979797 1px solid;
    height: auto; 
  }

  &__text {
    margin-top: 1.1039em;
    margin-bottom: 1.1039em;
    padding: 0 0.97403em;
    // height: 5.90909em;
    height: auto;
    overflow: hidden;
  }

  &__cat {
    display: block;
    margin-bottom: 2em;
    height: auto;
    color: #E80262;
    font-size: calc-em(14px);

    @include mobile {
      margin-top: 15px;
      font-size: calc-em(12px);
    }
  }
  
  &__status {
    position: relative;
    font: 1.29625em/1.19961em "roboto-light";
    letter-spacing: 0.01205em;
    margin-top: 0.77146em;
    padding-bottom: 0.72324em;
    border-bottom: #000000 1px solid; 
  }
  
  &__info {
    font: 0.9em/1.2em "roboto-black";
    letter-spacing: -0.01181em;
    color: #9b9b9b;
    margin-top: 0.55556em; 
  }
  
  &--other {
    text-align: left; 
  }    
  
  &--other .border {
    position: absolute;
    top: 0;
    left: 0.5625em;
    right: 0.5625em;
    bottom: 0;
    margin-top: 14%;
    border-left: #9b9b9b 0.0625em solid;
    border-right: #9b9b9b 0.0625em solid;
    pointer-events: none; 
  }
  
  &--other .border__top, 
  &--other .border__bottom {
    position: absolute;
    width: 100%;
    height: 14%; 
  }

  &--other .border__top:before, 
  &--other .border__top:after, 
  &--other .border__bottom:before, 
  &--other .border__bottom:after {
    content: '';
    position: absolute;
    width: 50%;
    height: 100%; 
  }
  
  &--other .border__top {
    top: 0;
  }
        
  &--other .border__top:before, 
  &--other .border__top:after {
    border-top: #9b9b9b 0.0625em solid; 
  }
  
  &--other .border__top:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(-13.5deg);
    transform-origin: 0 0;
  }
  
  &--other .border__top:after {
    right: 0;
    transform: skewY(13.5deg);
    transform-origin: 100% 0;
  }
    
  &--other .border__bottom {
    bottom: 1px;
  }
      
  &--other .border__bottom:before, 
  &--other .border__bottom:after {
    border-bottom: #9b9b9b 0.0625em solid;
  }
  
  &--other .border__bottom:before {
    left: 0;
    padding-right: 1px;
    transform: skewY(13.5deg);
    transform-origin: 0 0;
  }

  &--other .border__bottom:after {
    right: 0;
    transform: skewY(-13.5deg);
    transform-origin: 100% 0;
  }
  
  &--other:after {
    display: none; 
  }
  
  &--other .residents__time {
    margin-bottom: 0.20833em;
  }
  
  &--other .tagline-item {
    padding-left: 1.5em;
    padding-right: 2em;
    font: 0.75em/1.33333em "roboto-light"; 
  }
    
  &--other .tagline-item:first-child {
    padding-right: 1.66667em; 
  }
    
  &--other .tagline-item:last-child {
    padding-left: 1.91667em;
  }
    
  &--other .tagline-item--active {
    font-family: "roboto-bold";
    line-height: 1.16667em; 
  }
  
  &--learning .resident__title {
    margin: 0.72324em 1.2054em 0;
    padding-bottom: 0.9161em;
    border-bottom: #979797 1px solid;
    height: auto; 
  }
  
  &--learning .resident__text {
    margin-top: 1.1039em;
    padding: 0 0.97403em;
    height: 5.90909em;
    overflow: hidden; 
  }
  
  &__other-content {
    display: block;
    margin: 0 1.1875em 0 1.4375em;
    padding-bottom: 0.6875em; 
  }
  
  &__heading {
    display: block;
    border-bottom: #000000 1px solid;
    padding-bottom: 0.5625em; 
  }
  
  &__time {
    display: block;
    margin-top: 1.25em;
    color: #000000;
    font: 1.5em/0.83333em "roboto-light";
    letter-spacing: 0.01042em;
  }
  
  &__text {
    display: inline-block;
    color: #4a4a4a;
    font: 0.9625em/1.41169em "roboto-light";
    letter-spacing: -0.00649em;

    @include mobile {
      display: none;
    }
  }
  
  &__text-title {
    display: block;
    color: #000000;
    font: 1.225em/0.95em "GT-Walsheim-Black";
    margin: 0.91837em 0 0.61224em; 
  }

  &-news {
    display: block;
    position: relative;
    margin-bottom: 3.75em;
    padding-bottom: 1.4375em;
    text-align: center;
    text-decoration: none;
    border: #9b9b9b 0.0625em solid;
    border-bottom: none;
    transition: opacity .3s ease;
    // background-color: #ffffff;
    color: #000;
    
    &:before,
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 50%;
      height: 12%;
      border-bottom: #9b9b9b 0.0625em solid;
    }

    &:before {
      left: 0;
      padding-right: 1px;
      transform-origin: 0 0;
      transform: skewY(12deg);
    }

    &:after {
      right: 0;
      transform-origin: 100% 0;
      transform: skewY(-12deg);
    }

    &__image {
      display: block;
      width: 100%;

      &__img {
        display: block;
        width: 100%;
      }

      .residents__svg-image {
        transform-origin: center center;
        transform: scale(1);
      }
    }

    &__title {
      font-size: 1.25em;
      // margin-top: 15px;
      font: 1.29625em/1.04966em "GT-Walsheim-Black";
      // height: 2.66104em;
      position: relative;
      // padding: 0.63657em 1.15741em 0;
      letter-spacing: -0.02083em;
      display: block;
      color: #000000;
      margin: 0.72324em 1.2054em 0;
      padding-bottom: 1em;
      border-bottom: #979797 1px solid;
      @include mobile {
       font-size: 12px;
      }
    }

    &__info {
      display: inline-block;
      margin-top: 25px;

      .residents-news__date {
        font-size: 1em;
        font-weight: bold;
        color: black;
        font-family: 'GT-Walsheim-Black';
        padding: 0;
        @include mobile {
          font-size: 12px;
         }

        img {
          width: 20px;
          height: 20px;
          display: inline-block;
          margin-right: 12px;
          vertical-align: middle;
          @include mobile {
            width: 15px;
            height: 15px;
           }
        }
      }
    }

    .list-tagline {
      margin-top: 15px;
      text-transform: uppercase;
      display: block;
      margin: 1.0625em 1.0625em 0;
      position: relative;
      min-height: 1.25em;
      background-color: #fff200;
      letter-spacing: -5px;
    }

    .list-tagline--empty {
      display: none;
    }

    .more-resource {
      &__subtext {
        // display: block;
        // font-family: 'roboto-light';
        // font-size: calc-em(14px);
        // margin: 1.5em 0 10px;
        // padding: 0 2.5em;
        // line-height: 1.5;

        font: 0.9em/1.5em "roboto-light";
        color: #000000;
        margin: 1.5em 1.04167em 0;
        // height: 4.72222em;
        height: auto;
        overflow: hidden;
        display: block;
        position: relative;
        @include mobile {
          font-size: 12px;
         }
      }

      &__text {
        // display: block;
        // padding: 0 2.5em;

        line-height: 1.3;
        display: block;
        font-family: "roboto-light";
        font-size: 1.25em;
        color: #000000;
        margin: 10px 0 0 0;
        // padding: 5px 0 10px;
        padding: 0 1.5em;
        font-weight: 600;
      }
    }
  }
}