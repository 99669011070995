// feature-residents styles
.featured-list {
  padding: 3em 0;
  background: linear-gradient(356.77deg, #ffffff 5.45%, #f0f0f0 98.09%);

  @include mobile {
    padding: 2em 1em;
  }

  &.no-background {
    background: transparent;
  }

  .title__wrapper {
    margin: 0 0 3em;

    .sub-title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: calc-em(17px);
      font-family: "roboto-bold";
      letter-spacing: 2px;
      margin: 10px auto 0;
    }
  }

  .button__wrapper {
    margin: 1.5em 0 0;

    .btn {
      max-width: 18.0625em;
    }
  }

  .residents {
    &-wrap {
      .residencies--card {
        .residents {
          &-item__text {
            padding: 1.5em 0 0;

            .residents {
              &__date {
                text-transform: uppercase;
                font-family: "roboto-light";
                font-size: calc-em(20px);
                color: #000;
              }

              &__title {
                margin-top: 0.5em;
              }

              &__logo {
                display: block;
                padding: 20px 0 0;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 479px) {
    // .news-wrap {
    //   [class^="col-"], [class*=" col-"] {
    //     width: 100%;
    //   }
    // }
  }
}

@include mobile {
  .featured-list {
    .residents-wrap .residencies--card .residents-item__text {
      padding-top: 10px;

      .residents {
        margin-bottom: 30px;
      }

      .residents__date {
        font-size: calc-em(12px);
      }

      .residents__title {
        margin: 10px 10px 0;
        font-size: calc-em(12px);
      }

      .residents__text {
        padding: 0 10px;
      }
    }
  }
}
