// feature-residents-news styles
.featured-news,
.residencies-listing {
  // padding: 3em 0;
  padding: 0;

  &.background {
    // background: linear-gradient(356.77deg, #FFFFFF 5.45%, #F0F0F0 98.09%);
    background: none;
  }

  @include mobile {
    padding: 2em 1em;
  }

  .event-calendar__title {
    // word-break: break-all;
    @include mobile {
      font-size: 2.5em;
    }
  }

  .button__wrapper {
    margin-top: 1em;

    .btn {
      max-width: 18.0625em;
    }
  }

  &.associated-events--list-view {
    .events__list {
      .col-md-4,
      .col-sm-4 {
        width: 50%;

        @include mobile {
          width: 100%;
        }

        .residents {
          border: none;
          text-align: left;
          margin: 0 0 1em;

          &:before,
          &:after {
            display: none;
          }

          .border {
            display: none;
          }

          &-item__text {
            padding: 1em;

            .residents {
              &__title,
              &__text {
                margin-right: 0;
                margin-left: 0;
                padding-right: 0;
                padding-left: 0;
              }

              &__title {
                border-bottom: none;
                padding-bottom: 0;
              }
            }
          }

          &__image {
            display: none;
          }

          &-news {
            &__image {
              display: none;
            }

            &__title {
              padding: 0 3em 0 0;
              margin: 1em 0 0.5em;
              border-bottom: none;

              @include mobile {
                padding: 0;
              }
            }

            &__text {
              padding: 0 3em 0 0;

              @include mobile {
                padding: 0;
              }

              .more-resource__subtext {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  .slider-events {
    .slider-events__item {
      &:after {
        border-bottom-color: transparent;
      }
    }
  }

  .events-filter__display,
  .residencie-filter {
    .custom-select__options {
      width: 100%;

      &.is-active {
        display: table;
      }

      ul {
        position: relative;
        padding-right: 20px;

        li {
          white-space: nowrap;
        }
      }
    }
  }

  @media only screen and (max-width: 479px) {
    // &.associated-events--news {
    //   [class^="col-"], [class*=" col-"] {
    //     width: 100%;
    //   }
    // }
  }
}
