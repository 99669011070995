// direction-tabs styles
.directions {
    position: relative;
    z-index: 1;
    padding: 68px 0 86px;
    background-color: #f3f3f3;

    &__nav {
        ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #989898;
        }

        li {
            display: block;
            margin-bottom: -1px;
        }

        a {
            display: block;
            padding: 14px 0;
            color: #989898;
            font-weight: 700;
            font-size: calc-em(18px);
            line-height: 40px;
            position: relative;
            vertical-align: middle;

            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                width: 0;
                vertical-align: middle;
            }
        }

        i {
            font-size: calc-em(24px, 18px);
            display: inline-block;
            height: 40px;
            position: relative;
            vertical-align: middle;
            padding: 0 20px 0 0;

            &:before {
                position: relative;
                top: 50%;
                display: inline-block;
                transform: translate(0, -50%);
            }
        }

        span {
            display: inline-block;
            position: relative;
            line-height: 40px;
            vertical-align: middle;
        }
    }

    &__info {
        padding: 35px 0;

        p {
            font-size: calc-em(18px, 22px);
            line-height: 30px;
        }
    }

    &__map {

    }
}

@media only screen and (min-width: 768px){
    .directions {
        &__nav {
            a {
                padding: 8px 0;
                font-size: calc-em(16px, 12px);
            }

            i {
                font-size: calc-em(26px, 16px);
                padding: 0 10px 0 0;
            }

            li {
                &:hover,
                &.active {
                    border-bottom: 4px solid #000;

                    a {
                        color: #000;
                    }
                }
            }
        }

        &__info {
            padding: 20px 0;

            p {
                font-size: calc-em(14px, 12px);
                line-height: 30px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) {
    .directions {
        &__nav {
            a {
                padding: 14px 0;
                font-size: calc-em(18px);
            }

            i {
                font-size: calc-em(24px, 18px);
                padding: 0 20px 0 0;
            }
        }

        &__info {
            padding: 35px 0;

            p {
                font-size: calc-em(22px, 22px);
                line-height: 30px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .directions {
        background-color: #fbfbfb;
        .container {
            padding: 0;
        }

        &__nav {
            padding: 0 15px;
            position: relative;

            .nav-toggle {
                p {
                    font-size: calc-em(14px);
                    font-weight: 700;
                    display: block;
                    text-align: center;
                    border: 1px solid #000;
                    padding: 2px 0;
                }

                i {
                    font-size: calc-em(12px, 13px);
                    display: inline-block;
                    transform: rotate(90deg);
                    transform-origin: 50% 50%;
                    padding: 0 0 15px;
                    transition: all 0.3s;
                }

            }

            &.active {
                .nav-toggle i {
                    transform: rotate(90deg) scaleX(-1);
                }

                ul {
                    opacity: 1;
                    visibility: visible;
                }
            }

            ul {
                position: absolute;
                opacity: 0;
                visibility: hidden;
                transition: all 0.3s;
                top: calc(100% - 1px);
                left: 15px;
                width: calc(100% - 30px);
                border: 1px solid #000;
                display: block;
                background-color: #fbfbfb;
                box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.1);
            }

            li {
                display: block;
                border-bottom: 1px solid #000;

                &.active {
                    border-bottom: 1px solid #000;
                }

                &:last-child {
                    border: none;
                }
            }

            a {
                color: #000;
                line-height: normal;
                text-align: center;
                padding: 12px 0;
                font-size: calc-em(14px);
                font-weight: 400;
            }

            span {
                line-height: normal;
            }
        }

        &__contents {
            padding: 0 15px;
        }

        &__map {
            iframe {
                width: 100% !important;
            }
        }
    }
}
