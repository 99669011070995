// site-footer styles

// Footer new icons
.social-share .icon-google-arts-culture .social-share__icon:after,
.social-share .icon-google-arts-culture .social-share__icon--menu:after {
    color: #009eed;
}

.social-share .icon-trip-advisor .social-share__icon:after,
.social-share .icon-trip-advisor .social-share__icon--menu:after {
    color: #00AF87;
}

.social-share .icon-spotify .social-share__icon:after,
.social-share .icon-spotify .social-share__icon--menu:after {
    color: #1DB954;
}


@media screen and (max-width: 47.9375em) {
    .social-share__item {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 5px;
    }

    .social-share__item:first-child {
        margin-left: 16px;
    }

    .social-share__item:last-child {
        margin-right: 16px;
    }
}
