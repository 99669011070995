// highlight-work styles
.highlight__image {
    @include aspect-ratio(440, 332, 100%);

    img {
        @include image-cover;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.highlight__ico-slideshow {
    right: 0.625em;
    bottom: 0.625em;
    font-size: 1.5em;
}

@media screen and (max-width: 47.9375em) {
    .highlight-work {
        padding-top: 10vw;

        &.highlight-work--revert {
            padding-top: 0;
        }
    }

    .highlight__desc {
        margin-top: 1.875em;
    }
}

@media screen and (min-width: 48em) {
    .highlight-work .row:not(:first-child) {
        margin-top: 0;
    }

    .highlight-work .row {
        margin-top: 30px;
        margin-bottom: 30px;
    }
}
