// other-exhibitions styles
.exhibition--other .border {
    left: 0;
    right: 0;
    margin-top: 0;
    border-top: #9b9b9b 0.0625em solid;
}
.other-exhibition__wrapper {
    @media screen and (max-width: 47.9375em) {
        .exhibition__text-title {
            word-wrap: break-word;
        }

        .row {
            padding: 0 0.4375em;
        }

        .col-4 {
            width: 50%;
        }
    }

    @media only screen and (max-width: 479px){
        .col-4 {
            width: 100%;
        }
    }
}