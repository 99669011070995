//helper classes
%list-unstyled {
	@include normalise-list;
}

%clearfix {
	@include clearfix;
}

%visuallyHidden {
	@include visuallyHidden;
}

.visuallyHidden, 
.sr-only {
	@extend %visuallyHidden;
}

%background-cover {
	@include background-cover;
}

%image-cover {
	@include image-cover;
}

.show {
	display: block !important;
}

.hide {
	display: none !important;
}

.hidden {
	visibility: hidden !important;
}

@media only screen and (max-width: $bpTablet - 1) {
	.tablet-only, .tablet-desktop {
		display: none !important;
	}
}

@media only screen and (min-width: $bpTablet), print {
	.mobile-only {
		display: none !important;
	}
}

@media only screen and (max-width: $bpDesktop - 1) {
	.desktop-only {
		display: none !important;
	}
}

@media only screen and (min-width: $bpDesktop), print {
	.tablet-only, .mobile-tablet {
		display: none !important;
	}
}

.visuallyhidden-focusable:active,
.visuallyhidden-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}
