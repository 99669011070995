// exhibition-programme styles
.exhibition-programme {
    $p: &;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 28px 0;
    gap: 16px;

    @include tablet {
        flex-direction: row;
        margin: 40px 0;
        gap: 24px;
    }

    &__media {
        position: relative;

        @include tablet {
            flex: 1 40%;
        }

        &--image {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;

            picture, img {
                position: relative;
                display: block;
                width: 100%;
                height: auto;
                object-fit: cover;
            }

            @include desktop {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 26px;

        @include tablet {
            flex: 1 60%;
        }

        &__period {
            flex: 1 25%;

            &--session {
                margin-bottom: 14px;

                &--date {
                    font-family: 'GT-Walsheim-Black';
                    font-size: 64px;
                    line-height: 1.2;
                }

                &--month {
                    font-size: 15px;
                    line-height: 1.5;

                    @include tablet {
                        font-size: 20px;
                    }
                }

                &--text {
                    font-family: 'GT-Walsheim-Black';
                    font-size: 16px;
                    line-height: 1.4;

                    @include tablet {
                        font-size: 20px;
                    }
                }
            }

            &--time {
                border-top: 1px solid black;
                padding-top: 14px;

                &--day {
                    font-weight: bold;
                    font-size: 15px;
                    line-height: 1.5;

                    @include tablet {
                        font-size: 16px;
                    }
                }

                &--hour {
                    font-family: 'roboto-light';
                    font-size: 18px;
                    line-height: 1.4;

                    @include tablet {
                        font-size: 20px;
                    }
                }

                &--text {
                    font-family: 'roboto-light';
                    font-size: 14px;
                    line-height: 1.4;

                    @include tablet {
                        font-size: 16px;
                    }
                }
            }
        }

        &__detail {
            display: flex;
            flex-direction: column;
            flex: 1 75%;
            gap: 10px;

            &--title {
                h2, h3 {
                    font-family: 'GT-Walsheim-Black';
                    font-size: 20px;
                    line-height: 1.4;
                    margin-bottom: 0 !important;
                }
            }

            &--text {
                font-family: 'roboto-light';
                font-size: 14px;
                line-height: 1.4;
                margin-bottom: 10px;
            }

            &--related {
                margin-bottom: 6px; 

                &--text {
                    font-family: 'roboto-light';
                    font-size: 14px;
                    line-height: 1.4;
                }

                &--name {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 1.4;
                    color: #015aff;
                }
            }

            &--cta {
                .btn {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 12px;
                    font-size: 12px;
                    line-height: 1.5;
                    font-weight: bold;

                    @include tablet {
                        font-size: 16px;
                        line-height: 1.5;
                    }

                    &--reserve {
                        background: white;
                        color: black;
                        border: 2px solid black;
                        width: 140px;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            border-color: $btn-border;
                        }

                        @include tablet {
                            width: 190px;
                        }
                    }
                }
            }
        }
    }
}