//Media query breakpoints
$bpMobile: 320px;
$bpLgMobile: 420px;
$bpTablet: 768px;
$bpDesktop: 1024px;
$bpLgDesktop: 1280px;

//font settings
$base-fontsize: 16px;
$headerFont: '', Arial, sans-serif;
$primaryFont: '', Arial, sans-serif;

/* COLOURS */
$sam-pink : #e80262;
$sam-yellow : #fff100;
$sam-blue: #0016b7;
$sam-red: #BA024E;
$sam-white: #ffffff;
$sam-white2: #f2f2f2;

$btn-border: #ff00ac;
$search-border: #9b9b9b;
$megamenu-border: #e7e7e7;
$megamenu-writeupGrey: #696969;
$megamenu-background: #f9f9f9;
$mobilemenu-border: #dbdbdb;
$mobilemenu-level2: #f1f1f1;
