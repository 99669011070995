// timeline-item styles
.timeline-item {
    display: block;
    margin: 0 25px;
    text-decoration: none;
    outline: 0;

    &:hover {
        .timeline-item__image {
            .hover-effect {
                &:after {
                    transform: translate(-50%, -50%) scale(1.0);
                }

                p {
                    transform: scale(1.0);
                    opacity: 1;
                }
            }
        }
    }


    &__image {
        @include aspect-ratio(299px, 188px, 100%);
        border: 1px solid #dedede;

        img {
            @extend %image-cover;
            position: absolute;
            top: 0;
            left: 0;
        }

        .hover-effect {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            text-align: center;
            vertical-align: middle;

            &:before {
                content: '';
                display: inline-block;
                height: 100%;
                width: 0;
                vertical-align: middle;
            }

            &:after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                border: 1px solid #fff;
                width: calc(100% - 38px);
                height: calc(100% - 38px);
                transition: all 0.3s;
            }

            p {
                display: inline-block;
                position: relative;
                vertical-align: middle;
                transform: scale(1.8);
                font-family: 'roboto-bold';
                color: #fff;
                opacity: 0;
                line-height: 100%;
                transition: all 0.3s;
            }
        }
    }

    &__text {
        h3 {
            font-size: calc-em(16px, 18px);
            font-family: 'GT-Walsheim-Black';
            line-height: 30px;
            margin: 16px 0 6px;
            color: $sam-pink;
        }

        p {
            font-size: calc-em(14px, 18px);
            line-height: 24px;
            margin-bottom: 16px;
            color: #000;
        }
    }
}


// // from 1920px
// @media screen and (min-width: 120em) {
//     body {
//         font-size: 22px;
//     }
//     .timeline-item {
//         &__text {
//             h3 {
//                 font-size: calc-em(16px, 22px);
//             }

//             p {
//                 font-size: calc-em(14px, 22px);
//             }
//         }
//     }
// }
// // from 1441px to 1919px
// @media screen and (min-width: 90.0625em) and (max-width: 119.9375em) {
//     .timeline-item {
//         &__text {
//             h3 {
//                 font-size: calc-em(16px, 18px);
//             }

//             p {
//                 font-size: calc-em(14px, 18px);
//             }
//         }
//     }
// }

// // from 1024px to 1440px
// @media screen and (min-width: 64em) and (max-width: 90em) {
//     .timeline-item {
//         &__text {
//             h3 {
//                 font-size: calc-em(16px, 15px);
//             }

//             p {
//                 font-size: calc-em(14px, 15px);
//             }
//         }
//     }
// }

// from 768px to 1023px
@media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .timeline-item {
        margin: 0 18px;

        // &__text {
        //     h3 {
        //         font-size: calc-em(14px, 12px);
        //     }
        // }
    }
}

// to 767px
@media screen and (max-width: 47.9375em) {
    .timeline-item {
        margin: 0 12px;
        // &__text {
        //     h3 {
        //         font-size: calc-em(14px, 16px);
        //     }

        //     p {
        //         font-size: calc-em(12px, 16px);
        //     }
        // }
    }
}
