// residents-cta styles
.residents {
  $p: &;

  &-cta {
    padding: 4em 0;

    @include mobile {
      padding: 2em 1em;
    }

    .cta {
      &-wrap {
        width: 70%;
        margin: 0 auto;

        @include mobile {
          width: 100%;
          padding: 0 1em;
        }

        &:after {
          content: "";
          display: table;
          clear: both;
        }
      }

      &-heading {
        font-family: "GT-Walsheim-Black";
        font-size: calc-em(14px);
        color: #000000;

        width: 50%;
        float: left;
        vertical-align: middle;
        text-align: right;
        padding: 0 30px 0 0;

        @include mobile {
          width: 100%;
          padding: 0;
          text-align: left;
        }

        &:after {
          display: block;
          content: '';
          background: #fff100;
          width: 100%;
          height: 10px;
        }

        .title {
          margin: 0 0 10px;
        }
      }

      &-content {
        width: 50%;
        float: left;
        vertical-align: middle;

        border-top: 1px solid #C4C4C4;
        border-bottom: 1px solid #C4C4C4;
        display: flex;
        align-items: center;

        @include mobile {
          width: 100%;
          padding: 1em 0;
        }

        a {
          font-family: 'roboto-bold';
          color: #E80262;
          transition: all .2s ease-in-out;

          &:hover {
            text-decoration: underline;
            color: #00e6fe;
          }
        }
      }
    }
  }
}