// maintenance styles
.maintenance-wrap {
    padding: 5em 0 3em;
    .title {
        margin-bottom: 25px;

        span {
            white-space: nowrap;
        }
    }
}

.main-footer.maintenance-footer {
    .main-footer__heading {
        text-align: center;
    }

    .social-share {
        float: none;
        clear: both;
    }
}

@media screen and (max-width: 767px) {
    .maintenance-wrap {
        .title {
    
            span {
                white-space: unset;
            }
        }
    }

    .main-footer.maintenance-footer {
        .social-share__item {
            margin-right: 5px;
            margin-left: 5px;

            &:first-child {
                margin-left: 5px;
            }
        }
    }
}
