// exhibition-widget styles
.exhibition-widget {
    position: relative;
    margin: 20px 0 0;
    z-index: 3;

    @include desktop {
        margin: 28px 0 0;
    }

    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        gap: 28px;

        @include desktop {
            width: 1440px;
            max-width: 100%;
            margin: 0 auto;
            padding: 0 104px;
        }
    }

    &__back {
        flex: 1;
    }

    &__sharing {
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        position: relative;
        font-weight: 700;

        > p {
            display: flex;
            gap: 6px;
            align-items: center;
            padding: 0 0 4px 0;

            &:focus,
            &:hover {
                & + .list-social {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: auto;
                }
            }
        }
        
        .list-social {
            border: none;

            @media (max-width: $bpDesktop - 1) {
                position: absolute;
                top: 100%;
                right: 0;
                padding: 10px 20px;
                margin: 0;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
                display: flex;
                gap: 16px;
                flex-direction: column;
                background-color: #fff;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                transition: all 0.3s ease-in-out;

                .social-item {
                    width: 100%;
                    padding: 0;
                    display: block;
                    float: none;
                }

                .social_link {
                    display: flex;
                    width: 100%;
                    height: auto;
                    align-items: center;
                    text-align: left;
                    position: relative;
                    transform: none;
                    padding: 0 0 0 30px;
                    font-weight: 700;

                    .icon {
                        top: 50%;
                        left: 15px;

                        &.ico-facebook {
                            color: rgba(24, 119, 242, 1);
                        }

                        &.ico-twitter {
                            color: rgba(29, 155, 240, 1);
                        }
                        
                        &.ico-email-black {
                            color: rgba(255, 0, 174, 1);
                        }
                    }
                }
            }

            @include desktop {
                display: flex;
                flex-wrap: nowrap;
            }

            li {
                padding: 0 10px 0;
            }

            .social_link {
                @include desktop {
                    font-size: calc-em(36px, 18px);
                    &:hover {
                        .ico-sam-facebook {
                            color: rgba(24, 119, 242, 1);
                        }

                        .ico-sam-twitter {
                            color: rgba(29, 155, 240, 1);
                        }
                        
                        .ico-sam-email {
                            color: rgba(255, 0, 174, 1);
                        }
                    }
                }
            }
        }
    }
}