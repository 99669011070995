// subscribe-validation styles
.newsletter .field-border,
.newsletter .sam-Newsletter_CheckboxList {
    position: relative;
}

.newsletter .field-error {
    // display: block;
    color: #fff;
    font-weight: 600;
    position: absolute;
    bottom: 100%;
    left: 1.85714em;
    transform: translate(0, -10px);

    &.sam-Newsletter_Interest {
        top: 100%;
        bottom: auto;
        transform: translate(0, 10px);
    }
}


