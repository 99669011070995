// back-button styles
.back-button {
    position: relative;
    margin: 0 0 1.5em;
    text-align: left;
    margin-left: -20px;
    border: none;
    font-size: calc-em(15px);
    color: #000;
    font-weight: 700;
    line-height: 1.3;

      &:hover {
        border: none;
        color: #e80262;
      }

      .arrow-ico {
        display: inline-block;
        transform: rotate(180deg);
        margin: 0 10px 0 0;
        height: 1.35em;
      }
}