// blog-tile styles
.blog-item {
    margin: 0 0 20px;

    &__image {
        @include aspect-ratio(311, 220, 100%);

        img {
            @include image-cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__text {
        background-color: #fff;
        padding: 20px 0;

        h3 {
            font-size: calc-em(22px);
            margin: 0 0 12px;
            font-family: 'GT-Walsheim-Black';

            a {
                color: #000;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .tags {
            @extend %clearfix;
            margin: 0 0 10px;

            span {
                float: left;
                display: inline-block;
                background-color: $sam-yellow;
                padding: 1px 2px;
                margin-right: 10px;
                font-size: calc-em(15px);
                line-height: 1.2;
            }
        }

        .date {
            font-size: calc-em(15px);
            line-height: 1.667;
            margin-bottom: 8px;

            i {
                display: inline-block;
                padding-right: 10px;
            }
        }

        .desc {
            font-size: calc-em(16px);
            line-height: 1.667;
            margin-bottom: 15px;
        }

        .download {
            text-decoration: none;
            color: $sam-pink;

            i {
                display: inline-block;
                padding-right: 10px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media only screen and (max-width: 63.9375em) {
    .blog-item {
        margin: 0 0 10px;
        padding: 0 1.25em;
    }
}


.gallery-module--list-view{
    .blog-item__image{
        display: none;
    }
    .match-height{
        height: auto !important;
    }
}