// selected-artwork styles
.selected-artwork {
    > .container {
        width: auto;
        padding: 0;

        .title__wrapper {
            text-align: start;
        }

        .row {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            max-width: 738px;
            margin: auto;
            justify-content: center;

            @include tablet {
                max-width: 738px;
            }
            @include desktop {
                max-width: 922px;
            }
        }

        // @include desktop {
        //     gap: 32px;
        // }

        &:before {
            content: none;
        }
    }

    &__item {
        // max-width: calc(50% - 16px);

        @include desktop {
            // max-width: calc(50% - 32px);
        }

        &__desc {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin: 14px 0;

            @include desktop {
                margin: 6px 0;
            }

            &--title {
                font-family: 'GT-Walsheim-Black';
                font-size: 14px;
                line-height: 1.5;
                margin: 0 !important;

                @include tablet {
                    font-size: 18px;
                }

                @include desktop {
                    font-size: 24px;
                }

            }

            &--name {
                font-size: 12px;
                line-height: 1.4;
                color: #ff00ae;

                @include tablet {
                    font-size: 14px;
                }

                @include desktop {
                    font-size: 16px;
                }
            }

            &--year {
                font-size: 12px;
                line-height: 1.4;

                @include tablet {
                    font-size: 14px;
                }

                @include desktop {
                    font-size: 16px;
                }
            }
            &--text {
                font-size: 12px;
                line-height: 1.4;
                margin-top: 6px;
                word-break: break-all;

                @include tablet {
                    font-size: 14px;
                }

                @include desktop {
                    font-size: 16px;
                    margin-top: 12px;
                }
            }
        }
    }
}

@media only screen and (max-width: 47.9375em){
    .selected-artwork {
        .col-4 {
            width: 50%;
        }
    }
}