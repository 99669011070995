// exhibition styles
.exhibition__wrapper {
  @media screen and (max-width: 47.9375em) {
    .row {
      padding: 0 0.4375em;
    }

    .col-4 {
      width: 50%;
    }
  }

  @media only screen and (max-width: 479px) {
    // .col-4 {
    //     width: 100%;
    // }
  }
}
