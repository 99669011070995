// resource-card styles
.more-resource .more-resource__title,
.more-resource .more-resource__text {
    height: auto;
    overflow: visible;
}

.card__name,
.card__title,
.card__date {
    height: auto;
    overflow: visible;
}

.more-resource .more-resource__subtitle {
    display: block;
    font-family: "roboto-light";
    font-size: calc-em(20px);
    color: #000000;
    padding: 5px 0 10px;
    font-weight: 600;
}

.more-resource--special {
    .list-tagline {
        text-transform: uppercase;
    }
}

.associated-events--list-view {
    .col-6 {
        padding: 0 50px;
    }
    .col-4 {
        width: 50%;
    }

    .more-resource__image {
        border: none;
        display: none;
    }

    .more-resource {
        text-align: left;
        border: none;
        background-color: transparent;
    }

    .more-resource__title {
        padding: 0;
        font-size: calc-em(18px);
        margin-bottom: 15px;
        height: auto !important;
    }

    .more-resource__text {
        height: auto !important;
    }

    .list-tagline {
        font-size: calc-em(14px);
    }

    .more-resource--special {
        padding: 0;
        margin-bottom: 40px;
    }
    .more-resource--special .more-resource__text {
        margin: 15px 0 0;
    }
    .more-resource__date {
        padding-top: 10px;
    }

    .more-resource__date, .card__date {
        height: auto !important;
        font-size: calc-em(12px);
    }

    .card__date {
        padding: 0;
    }

    .more-resource--special .list-tagline {
        margin: 0;
        display: inline-block;
        padding: 5px 15px;
    }

    .more-resource .more-resource__subtitle {
        font-size: calc-em(14px);
    }

    .more-resource:before, .more-resource:after {
        content: none;
    }
}

.associated-filter__type {
    padding: 0 20px 15px;
    text-align: right;
    .view-type {
        display: inline-block;

        &__item {
            padding-left: 15px;

            &.active {
                .ico {
                    color: #000;
                }
            }
            .ico {
                color: #a4a4a4;
            }
        }
    }
}

@media only screen and (max-width: 47.9375em){
    .associated-events {
        .col-4 {
            width: 50%;
        }

        .more-resource {
            margin: 0 0 15%;
        }
    }
    .associated-events--list-view {
        .col-6 {
            padding: 0;
        }
        &.associated-events .more-resource {
            margin: 0 0 15%;
        }

        .more-resource--special .more-resource__text {
            margin: 5px 0 0;
        }

        .more-resource__date, .card__date {
            font-size: calc-em(14px);
        }

        .more-resource--special .list-tagline {
            padding: 6px 10px;
        }
    }

    .associated-filter__type .events-filter__item {
        &:first-child {
            border-right: 1px solid #979797;
            padding-right: 15px;
            margin-right: 0;
        }

        .ico {
            font-size: 24px;
        }
    }
}

@media only screen and (max-width: 479px){
    .associated-events {
        .col-4 {
            width: 100%;
        }
    }
}
