// media-component styles
.media-component {
	margin-bottom: 30px;

	.media {
		padding-top: 56.25%;
		position: relative;

		iframe, video {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		video[poster]{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.image-container {
		width: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.caption {
		display: block;
		text-align: center;
		font-size: 12px;
		margin-top: 10px;
	}
}