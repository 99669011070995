// custom-checkbox styles
.checkbox-custom {
    margin-bottom: 20px;
    height: 20px;

    input {
        position: absolute;
        opacity: 0;

        + label {
            position: relative;
            cursor: pointer;
            padding: 0 0 0 38px;
            margin-bottom: 20px;
        }

        + label:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 0;
            width: 20px;
            height: 20px;
            vertical-align: text-top;
            border: 1px solid #9b9b9b;
            background-color: #fff;
            border-radius: 0px;
        }

        + label:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 4px;
            width: 12px;
            height: 12px;
            background-color: transparent;
        }

        &:hover + label:before,
        &:checked+label:before {
            border: 1px solid $sam-pink;
        }

        &:hover + label:after,
        &:checked + label:after {
            background-color: $sam-pink;
        }
    }
}
