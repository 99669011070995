// banner styles
.home-banner {
  position: relative;
  // margin-bottom: 50px;
  margin-bottom:20px;

  a {
    text-decoration: none;
    color: #fff;
  }

  &__item {
    position: relative;
  }
  &__image {
    position: relative;
    height: 80vh;
    // @include aspect-ratio(100, 50, 100%);
    img {
      @include image-cover;
      position: absolute;
      top: 0;
      left: 0;
    }

    // .video-controls {
    //   position: absolute;
    //   text-align: center;
    //   top: 20px;
    //   left: 50%;
    //   //   width: 100%;
    //   padding: 4px;
    //   transform: translate(-50%, 0);
    //   z-index: 6;
    //   background-color: rgba(255, 255, 255, 0.2);

    //   button {
    //     cursor: pointer;
    //     width: 32px;
    //     height: 32px;
    //     border: none;
    //     background-color: transparent;
    //     border-radius: 50%;
    //     margin-right: 15px;

    //     &:last-of-type {
    //       margin-right: 0;
    //     }

    //     @include mobile {
    //       width: 25px;
    //       height: 25px;
    //       margin-right: 20px;
    //     }

    //     &.active {
    //       svg {
    //         fill: #333;
    //       }
    //     }

    //     svg {
    //       fill: #fff;
    //     }
    //   }

    //   &--forward,
    //   &--backward {
    //     &:hover {
    //       svg {
    //         fill: #fff !important;
    //       }
    //     }
    //   }
    // }

    .video-container {
      height: 100%;
      // overflow: hidden;

      video {
        height: 100%;
        width: 177.77777778vh; /* 100 * 16 / 9 */
        min-width: 100%;
        height: 80vh; /* 100 * 9 / 16 */
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    // &:before {
    //   content: " ";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   z-index: 5;
    // }
  }
  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 80%;
    font-family: "GT-Walsheim-Black";
    text-align: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1;
      font-size: calc-em(144px);
    }
    p {
      margin-top: 8px;
      font-size: calc-em(26px);
    }
    > * {
      margin: 0;
    }
  }
  &__next {
    position: absolute;
    bottom: 7.5%;
    right: 7em;
    span {
      font-size: calc-em(14px);
    }
    p {
      font-size: calc-em(16px);
      font-family: "GT-Walsheim-Black";
    }
  }
  .banner-dots {
    position: absolute;
    bottom: 8%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, 0);
    text-align: center;
    ul {
      @extend %clearfix;
      display: inline-block;
    }
    li {
      display: inline-block;
      float: left;
      margin-right: 50px;
      width: 29px;
      height: 29px;
      position: relative;
      &:before {
        content: "";
        width: 0px;
        height: 0px;
        border-radius: 50%;
        border: 2px solid #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s;
      }
      &:after {
        content: "";
        position: absolute;
        top: 14px;
        left: 32px;
        width: 44px;
        border-top: 2px dotted #fff;
        // border-image-source: url(../img/dotted.svg);
        // border-image-slice: 33%;
        // border-image-repeat: round;
      }
      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
      &.slick-active {
        button {
          width: 13px;
          height: 13px;
        }
        &:before {
          width: 25px;
          height: 25px;
        }
      }
    }
    button {
      display: inline-block;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      border: none;
      background-color: #fff;
      color: #fff;
      overflow: hidden;
      outline: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s;
    }
  }
  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    border: none;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    @media only screen and (min-width:1920px) {
      height: 50px;
      width: 50px;
    }
    outline: 0;
    z-index: 1;
    cursor: pointer;
    box-shadow: rgba(0,0,0,0.24) 0px 3px 8px;
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: calc-em(34px);
      text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
    }
    &.slick-prev {
      left: 1.875em;
    }
    &.slick-next {
      right: 1.875em;
    }
  }

  &.residencies {
    margin: 0 0 4em;

    .slick-arrow {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.prev {
        padding: 0 3px 0 0;
      }

      &.next {
        padding: 0 0 0 2px;
      }

      i {
        position: relative;
        top: unset;
        left: unset;
        transform: none;
        font-size: calc-em(16px);
        color: #fff;
      }
    }

    .banner-cta {
      position: absolute;
      bottom: 8%;
      right: 60px;

      @include mobile {
        left: 0;
        right: unset;
        bottom: 20px;
        margin: 20px 0 0;
        width: 100%;
        text-align: center;
      }

      a {
        font-size: calc-em(14px);
        font-weight: 700;
        border: none;

        .arrow-ico {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 63.9357em) {
  .home-banner {
    .slick-arrows {
      width: 50px;
      height: 50px;
    }
    &__text {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: calc-em(72px, 12px) !important;
      }
      p {
        font-size: calc-em(20px, 12px) !important;
      }
    }
  }
}

// @media only screen and (max-width: 47.9375em){
//     .home-banner {
//         margin-bottom: 3.125em;
//         &__text {
//             max-width: 70%;
//             p { font-size: calc-em(20px); }
//             h1, h2, h3, h4, h5, h6 {
//                 font-size: calc-em(72px);
//             }
//         }
//         .slick-arrow {
//             i {
//                 font-size: calc-em(24px);
//             }
//             &.slick-prev {
//                 left: 1.25em;
//             }
//             &.slick-next {
//                 right: 1.25em;
//             }
//         }
//     }
// }
@media only screen and (max-width: 47.9375em) {
  .home-banner {
    &__image {
      padding-top: 0;
      height: calc(100vh - 88px);
      // @include aspect-ratio(640, 726, 100%);

      .video-container {
        width: 100%;
        height: 100%;
        overflow: hidden;

        video {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__text {
      p {
        font-size: calc-em(15px) !important;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: calc-em(38px) !important;
      }
    }
    .slick-dots {
      li {
        margin-right: 20px;
        &:after {
          content: "";
          top: 14px;
          left: 28px;
          width: 20px;
          border-top: 2px dotted #fff;
        }
        &.slick-active {
          button {
            width: 10px;
            height: 10px;
          }
          &:before {
            width: 20px;
            height: 20px;
          }
        }
      }
      button {
        width: 8px;
        height: 8px;
      }
    }
  }
}

@media only screen and (max-width: 39.9375em) {
  .home-banner {
    &__image {
      padding-top: 133%;
      height: 0;

      .video-container {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
