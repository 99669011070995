// site-header styles
%header-button {
    // width: 106px;
    // height: 106px;
    width: 56px;
    height: 56px;
    display: block;
    border: none;
    // outline: 0;
    cursor: pointer;
    background-color: #fff;
}

@keyframes popup {
    0% {
        transform: scale(0);
    }

    70% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.site-header {
    @extend %clearfix;
    padding-left: 1.875em;
    background-color: #fff;
    position: fixed;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    top: 0;
    z-index: 10;
    // box-shadow: 0 -3px 20px #acacac;
    transition: all 0.3s;

    &.box-shadow {
        box-shadow: 0 7px 12px -5px #acacac;
    }

    &__logo {
        float: left;
        padding: 15px 0;
        width: 25%;

        a,
        img {
            display: block;
        }
        img {
            width: auto;
            height: 68px;
        }

        .small-screen-logo {
            display: none;
        }

        .large-screen-logo {
            display: block;
        }
    }
    .main-menu {
        width: 75%;
        float: right;
        .nav-ticket {
            position: relative;
            padding-left: 25px;
            img{
                width: 25px;
                position: absolute;
                left: -5px;
                top: -3px;
            }
        }
    }

    &__nav,
    &__notification,
    &__search,
    &__openinghours {
        float: right;
    }

    &__nav {
        > ul {
            @extend %clearfix;
            > li {
                float: left;
                position: relative;

                &:hover,
                &:focus,
                &.on-focus {
                    > .site-header__megamenu {
                        opacity: 1;
                        visibility: visible;
                    }

                    > a {
                        border-bottom: 5px solid $sam-pink;
                    }
                }

                > a {
                    display: inline-block;
                    font-size: calc-em(16px);
                    line-height: 20px;
                    // padding: 43px 22px 38px;
                    margin: 18px 44px 0 0;
                    padding-bottom: 18px;
                    border-bottom: 4px solid transparent;
                    color: #000;
                    transition: all 0.3s;
                    font-family: "roboto-medium";

                    &:hover,
                    &:focus {
                        border-bottom: 5px solid $sam-pink;
                    }
                }
            }
            > li:last-child {
                a {
                    margin: 18px 20px 0 0;
                }
            }
        }
    }

    &__openinghours {
        // padding: 43px 20px 43px 72px;
        padding: 18px 20px 18px 72px;
        font-size: calc-em(16px);
        line-height: 20px;
        font-family: "roboto-medium";
        position: relative;

        i {
            display: inline-block;
            padding-right: 12px;
            font-size: 23px;
            position: absolute;
            top: 50%;
            left: 36px;
            transform: translate(0, -50%);
        }

        a {
            color: #000;
        }
    }

    &__notification {
        float: right;

        > button {
            @extend %header-button;
            font-size: calc-em(18px);
            color: #000;
            // margin-right: 15px;
            // margin-left: 10px;
            position: relative;
        }

        i {
            position: relative;
            display: inline-block;
        }

        .badge {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            position: absolute;
            top: -11px;
            right: -6px;
            font-family: "roboto-regular";
            border: 1px solid #fff;
            background-color: $sam-yellow;
            color: #000;
            padding: 3px 0;
            text-align: center;
            font-size: 12px;
            transform: scale(0);
            // transition: all 0.3s;

            &.popup {
                animation: popup 0.3s ease-out 0s 1;
                transform: scale(1);
            }

            &.blinkin {
                transform: scale(1);
            }
        }

        &.active button {
            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: calc(50% + 2.5px);
                transform: translate(-50%, 0);
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-right: 15px solid transparent;
                border-bottom: 15px solid $sam-yellow;
                border-left: 15px solid transparent;
            }
        }
    }

    &__notificationbar {
        // position: absolute;
        position: relative;
        // top: 100%;
        top: auto;
        left: 0;
        width: 100%;
        background-color: $sam-yellow;
        color: #000;
        font-family: "roboto-medium";
        font-size: calc-em(16px);
        line-height: 20px;
        padding: 22px 2em 22px 1.875em;
        // transition: all 0.3s;

        .site-header & {
            position: absolute;
            top: 100%;
        }

        &.notification-clone {
            position: relative;
            top: auto;
            left: 0;
            opacity: 0 !important;
        }

        .box-shadow & {
            box-shadow: 0 7px 12px -5px #acacac;
        }

        button {
            position: absolute;
            width: 16px;
            height: 16px;
            top: 50%;
            right: 1.875em;
            transform: translate(0, -50%);
            background-color: transparent;
            outline: 0;
            border: none;
            cursor: pointer;
        }
    }

    &__search {
        float: right;
        // position: relative;
        // margin-left: 20px;

        > button {
            @extend %header-button;
            background-color: #fff;
            color: #000;
            font-size: calc-em(23px);
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
        }
    }

    &__searchbar {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $sam-pink;
        color: #000;
        font-family: "roboto-medium";
        font-size: calc-em(16px);
        line-height: 20px;
        padding: 22px 1.875em;
        // transition: all 0.3s;

        .box-shadow & {
            box-shadow: 0 5px 20px -4px #acacac;
        }

        input {
            width: 100%;
            font-size: 1.725em;
            line-height: 1.2em;
            padding-left: 0.1938em;
            padding-top: 0.1938em;
            padding-bottom: 0.1938em;
            border: none;
            color: #fff;
            outline: 0;
            border-bottom: 1px solid $search-border;

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: #fff;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                color: #fff;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                color: #fff;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                color: #fff;
            }
        }

        button {
            position: absolute;
            top: 50%;
            right: 1.875em;
            border: none;
            padding: 0;
            background-color: transparent;
            color: #fff;
            font-size: calc-em(23px);
            transform: translateY(-50%);
            cursor: pointer;
        }
    }

    .menu__block__title {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: calc-em(13px);
        font-family: "roboto-medium";
        font-weight: normal;
        height: auto;
    }
}
.close-line {
    width: 100px;
    height: 100px;
    opacity: 1;

    &:before,
    &:after {
        position: absolute;
        left: 15px;
        top: 8px;
        content: " ";
        height: 19px;
        width: 2px;
        background-color: #fff;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}
@media screen and (min-width: 64em) and (max-width: 79.9375em) {
    %header-button {
        // width: 88px;
        // height: 88px;
        width: 56px;
        height: 56px;
    }

    .site-header {
        &__logo {
            img {
                width: auto;
                height: 50px;
            }
        }

        &__nav {
            > ul {
                > li {
                    > a {
                        font-size: calc-em(16px);
                        line-height: 20px;
                        // padding: 34px 12px 29px;
                        margin: 18px 44px 0 0;
                        padding-bottom: 18px;
                    }
                }
            }
        }

        &__openinghours {
            padding: 34px 10px 34px 52px;

            i {
                left: 16px;
            }
        }

        // &__notification {
        //     >button {
        //         margin-right: 10px;
        //         margin-left: 5px;
        //     }
        // }

        &__notificationbar {
            padding: 19px 2em 19px 1.875em;
        }

        &__search {
            margin-left: 10px;
        }

        &__searchbar {
            padding: 19px 1.875em;
        }
    }
}

@media screen and (max-width: 63.9375em) {
    %header-button {
        width: 88px;
        height: 88px;
    }

    .topheader {
        display: none;
    }

    .site-header {
        padding: 0 1.25em;

        &__logo {
            img {
                height: 50px;
            }
        }

        &__notification {
            &.active button {
                &:before {
                    left: 50%;
                    border-top: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 8px solid $sam-yellow;
                    border-left: 8px solid transparent;
                }
            }
        }

        &__notificationbar {
            font-size: calc-em(14px);
            padding: 15px 2em 15px 1.255em;
        }

        &__menu {
            float: right;

            > button {
                width: 61px;
                height: 88px;
                display: block;
                border: none;
                outline: 0;
                position: relative;
                background-color: #fff;
                cursor: pointer;
            }

            .line {
                position: absolute;
                width: 39px;
                height: 2px;
                background-color: #000;
                left: 20px;
                top: 43px;
                // transition: all 0.2s;

                &.line1 {
                    top: 33px;
                }

                &.line3 {
                    top: 53px;
                }

                &.active {
                }
            }
        }
        &__searchbar {
            padding: 22px 1.25em;

            input {
                font-size: 1em;
            }

            button {
                right: 1.25em;
                font-size: calc-em(18px);
            }
        }

        .topheader {
            display: none;
        }
    }
}

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
    .site-header {
        &__notificationbar {
            font-size: calc-em(16px);
        }
    }
    .close-line {
        width: 30px;
        height: 30px;
        opacity: 1;

        &:before,
        &:after {
            position: absolute;
            left: 20px;
            top: 28px;
            content: " ";
            height: 30px;
            width: 2px;
            background-color: #fff;
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
        &:hover {
            cursor: pointer;
        }
    }
}
@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .site-header {
        &__notification {
            > button {
                width: auto;
                padding: 0 20px;
                font-size: 28px;
                margin: 0;
            }

            .badge {
                width: 20px;
                height: 20px;
                top: -10px;
                right: -9px;
                font-size: 14px;
            }
        }

        &__search {
            > button {
                width: auto;
                padding: 0 20px;
                background-color: transparent;
                color: #000;
                font-size: 28px;
            }
        }
    }
}

@media screen and (max-width: 39.9375em) {
    .site-header {
        padding: 0 1.25em;
        .main-menu {
            width: 50%;
        }

        &__logo {
            padding: 13px 0;
            width: 50%;

            img {
                height: 30px;
            }

            .small-screen-logo {
                display: block;
            }
            .large-screen-logo {
                display: none;
            }
        }

        &__notification {
            > button {
                width: auto;
                height: 56px;
                padding: 0 10px;
                font-size: 16px;
                margin: 0;
            }

            .badge {
                width: 16px;
                height: 16px;
                top: -11px;
                right: -6px;
                font-size: 10px;
            }
        }

        &__search {
            > button {
                width: auto;
                height: 56px;
                padding: 0 10px;
                background-color: transparent;
                color: #000;
                font-size: 18px;
            }
        }

        &__menu {
            float: right;

            > button {
                width: 34px;
                height: 56px;
                display: block;
                border: none;
                outline: 0;
                position: relative;
                background-color: #fff;
                cursor: pointer;
            }

            .line {
                width: 24px;
                height: 2px;
                left: 10px;
                top: 27px;
                // transition: all 0.2s;

                &.line1 {
                    top: 22px;
                }

                &.line3 {
                    top: 32px;
                }
            }
        }
    }
}
