// mega-menu-item styles
.site-header__megamenu {
  @extend %clearfix;
  opacity: 0;
  visibility: hidden;
  // transition: all 0.3s;
  position: absolute;
  // top: 100%;
  // left: 0;
  background-color: $megamenu-background;
  // width: 100%;
  z-index: 2;

  &.flip {
    right: -10px;
    .nav-level2 ul {
      left: auto;
      right: 100%;
    }
  }

  &--top {
    @extend %clearfix;
    // width: 100%;
    width: 300px;

    > .col-md {
      &-3,
      &-6 {
        height: 100%;
        // padding: 42px 1.875em;
        border-right: 1px solid $megamenu-border;

        &:last-child {
          border: none;
        }
      }
    }
  }

  &--writeup {
    padding: 31px 1.875em 42px;

    h3 {
      font-family: "roboto-black";
      font-size: calc-em(16px);
      line-height: 38px;
      text-transform: uppercase;
      margin-bottom: 6px;
    }

    p {
      font-size: calc-em(14px);
      line-height: 20px;
      color: $megamenu-writeupGrey;
      margin-bottom: 17px;
    }

    a {
      display: inline-block;
      font-size: calc-em(14px);
      line-height: 20px;
      font-family: "roboto-bold";
      padding: 13px 60px;
      text-transform: capitalise;
      color: #000;
      border: 2px solid #000;
    }
  }

  &--innernav {
    @extend %clearfix;
    padding: 0 17px;

    > ul {
      @extend %clearfix;
      position: relative;
      height: 100%;
      // border-right: 1px solid $megamenu-border;
      padding: 30px 0 42px;
    }

    .nav-level2,
    .nav-level3 {
      display: inline-block;
      width: 100%;
      float: left;
      // width: 300px;

      a {
        display: block;
        font-size: calc-em(16px);
        line-height: 24px;
        font-family: "roboto-medium";
        color: #000;
        transition: all 0.3s;
        padding: 8px 40px 8px 25px;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;

        &:hover,
        &:focus {
          background-color: #000;
          color: #fff;
        }
      }

      > ul {
        position: absolute;
        width: 100%;
        top: 0;
        left: 100%;
        padding: 30px 0 42px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s;
        background-color: $megamenu-background;
        max-height: 550px;
        overflow-y: auto;

        // &.max-height {
        //   max-height: 550px;
        // }
        // .label2 {
        //     font-style: italic;
        // }
      }
    }

    .nav-level2 {
      // > ul {
      //   height: 100%;
      // }

      a {
        > i {
          position: absolute;
          top: 50%;
          right: 27px;
          transform: translate(0, -50%);
        }
      }
    }

    .nav-level2:hover,
    .nav-level2:focus,
    .nav-level2.on-focus {
      > ul {
        opacity: 1;
        visibility: visible;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 4px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #808080;
          border-radius: 8px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #b30000;
        }
      }
    }
  }

  &--featured {
    padding: 44px 58px;
  }

  .featured {
    &__image {
      @include aspect-ratio(284, 166, 100%);

      span {
        color: $sam-yellow;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &--bottom {
    @extend %clearfix;
    width: 100%;
    background-color: $sam-pink;
    padding: 0 1.875em;
    // height: 86px;
    font-family: "roboto-medium";

    .secondary-nav {
      float: left;

      ul {
        @extend %clearfix;
      }

      li {
        float: left;
        padding: 33px 22px 33px 0;
      }

      a {
        font-size: calc-em(16px);
        line-height: 20px;
        color: #fff;
      }
    }

    .social-nav {
      float: right;

      > span {
        float: left;
        color: #fff;
        font-size: calc-em(16px);
        line-height: 20px;
        padding: 33px 22px;
      }

      ul {
        display: inline-block;
        padding: 25px 0;

        a {
          margin: 0 0.8125em;

          &:before {
            color: #fff;
          }
        }
      }
    }
  }
}

.site-header {
  .topheader {
    width: 100%;
    display: inline-block;
    .topNav {
      background-color: #e70362;
      float: right;
      .icon{
        padding-right: 5px;
      }
      li {
        display: inline-block;
        padding: 10px 15px;

        a {
          color: #fff;
          text-decoration: none;
          font-size: 14px;
          font-weight: 500;
        }
      }

      li:first-child {
        padding-left: 35px;
      }
      li:last-child {
        padding-right: 35px;
      }
    }
  }
}
@media screen and (max-width: 63.9375em) {
  .site-header .topheader {
    display: none;
  }
  .social-nav .social-share {
    border-bottom: 0;
    margin-top: 1.6875em;
    margin-bottom: 1.1875em;
    padding: 0;
    background-color: #0000001a;
    display: inline-block;
  }
}

@media screen and (min-width: 90.0625em) and (max-width: 119.9375em) {
  .site-header__megamenu {
    &--bottom {
      .social-nav {
        ul {
          padding: 22px 0;
        }
      }
    }
  }
}

@media screen and (min-width: 120em) {
  .site-header__megamenu {
    &--bottom {
      .secondary-nav {
        li {
          padding: 31px 22px 30px 0;
        }
      }
      .social-nav {
        ul {
          padding: 17px 0 16px;
        }
      }
    }
  }
}
