// pagination styles
.pagination {
    text-align: center;
    margin: 10px 0 70px;

    ul {
        @extend %clearfix;
        display: inline-block;
    }

    li {
        float: left;
        display: inline-block;
        padding: 0 13px;
    }

    a {
        color: #000;
        display: inline-block;
        padding: 8px 5px;
        border-bottom: 3px solid transparent;

        i {
            display: inline-block;
            position: relative;
            top: 1px;
        }

        &.active, &:hover {
            color: $sam-pink;
            border-bottom: 3px solid $sam-pink;
        }
    }
}

@media only screen and (max-width: 63.9375em){
    .pagination {
        margin: 0 0 50px;
    }
}
