// read-more styles
@keyframes spin {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

.readmore {
  $p: &;
  text-align: center;
  padding: 0 1em;

  @include desktop {
    padding: 0 9em;
  }

  &-text {
    display: none;
    margin: 1em 0;
  }

  &-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2em 0 1em;

    &:before,
    &:after {
      content: '';
      display: block;
      position: relative;
      width: 10%;
      height: 1px;
      background: #cecece;
    }

    .btn--readmore {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 20px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
  
      span {
        line-height: 1;
        font-weight: 700;
        font-size: calc-em(12px);
        transition: all .2s ease-in-out;
        margin: 0 5px 0 0;
      }
  
      .icon-close {
        display: block;
        transition: all .2s ease-in-out;
        transform: rotate(135deg);
        
        g {
          display: block;
          transition: all .2s ease-in-out;
          stroke: #000;
        }
      }
  
      &:hover,
      &:focus { 
        outline: 0;
      }
  
      &:hover {
        @include desktop {
          span {
            color: #ff00ae;
          }
  
          .icon-close {
            g {            
              stroke: #ff00ae;
            }
          }
        }
      }

      &.more {
        .icon-close {
          transform: rotate(90deg);
        }
      }
    }
  }
}