// back-to-top styles
.back-to-top {
  border-radius: 50%;
  background-color: black;
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.25s ease-in-out;
  position: fixed;
  right: 36px;
  bottom: 36px;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  svg {
    transition: transform 0.3s ease-in-out;

    path {
      stroke: #fff;
      fill: #fff;
    }
  }
}
