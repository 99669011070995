// notification styles
.site-header__notificationbar {
    > div {
        position: relative;
        padding-right: 70px;
    }

    > button {
        i {
            display: block;
            position: relative;
            top: 1px;
        }
    }

    .carousel {
        p {
            display: none;
        }

        p:first-child {
            display: block;
        }

        button {
            width: 20px;
            height: 20px;
            right: -40px;
            cursor: pointer;

            &.slick-prev {
                transform: translate(-100%, -50%);
            }

            i {
                display: block;
                position: relative;
                top: 1px;
            }
        }
    }

    &.has-indicator {
        > div {
            padding-right: 40px;
        }

        .carousel {
            padding-right: 100px;

            button {
                right: 0;
            }
        }
    }

    .paging {
        position: absolute;
        top: 50%;
        right: 100px;
        transform: translate(0, -50%);

        p {
            margin: 0;
        }
    }
}

@media only screen and (max-width: $bpTablet - 1){
    .site-header__notificationbar {
        .carousel {
            button {
                right: -40px;
            }
        }

        > button {
            right: 16px;
        }

        > div {
            padding-right: 50px;
        }

        &.has-indicator {
            > div {
                padding-right: 50px;
            }

            .carousel {
                padding-right: 0;

                button {
                    right: -40px;
                }
            }
        }
    }
}
