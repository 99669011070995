// mobile-nav styles
.site-header__mobile {
    // position: absolute;
    position: fixed;
    top: 56px;
    left: 0;
    // right: 0;
    // width: 290px;
    width: 100%;
    // background-color: $megamenu-background;
    background: #191E24;
    // opacity: 0.8;
    font-family: "roboto-medium";
    transform: translate(100%, 0);
    transition: all 0.3s;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 56px;

    .mobile-siteHeader {
        width: 290px;
        position: absolute;
        right: 0;
        background-color: $megamenu-background;


        min-height: calc(100% - 56px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        
        // height: 100%;
    }

    &.active {
        transform: translate(0, 0);
    }

    ul {
        @extend %clearfix;
    }

    &--openinghours {
        padding: 21px 0 21px 50px;
        font-size: calc-em(14px);
        line-height: 20px;
        position: relative;

        i {
            display: inline-block;
            font-size: 20px;
            position: absolute;
            top: 50%;
            left: 18px;
            transform: translate(0, -50%);
        }
    }

    &--nav {
        .nav-level1 {
            display: block;
            font-size: calc-em(14px);
            line-height: 20px;
            position: relative;
            transition: all 0.3s;

            &:after {
                content: "";
                position: absolute;
                left: 18px;
                right: 18px;
                bottom: 0;
                height: 1px;
                background-color: $mobilemenu-border;
            }

            > a {
                color: #000;
                padding: 21px 18px;
                display: block;
                transition: all 0.3s;
                position: relative;

                > button {
                    position: absolute;
                    top: 50%;
                    right: 5px;
                    transform: translate(0, -50%);
                    transition: all 0.3s;
                    display: inline-block;
                    border: none;
                    outline: 0;
                    background-color: transparent;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;

                    &:before {
                        content: "";
                        position: absolute;
                        display: inline-block;
                        top: 50%;
                        // right: 16px;
                        // transform: translate(0, -50%);
                        right: 22px;
                        transform: rotate(-45deg);
                        width: 2px;
                        height: 8px;
                        background-color: #000;
                    }

                    &:after {
                        content: "";
                        position: absolute;
                        display: inline-block;
                        // top: 50%;
                        // right: 13px;
                        // transform: translate(0, -50%);
                        top: 58%;
                        right: 14px;
                        -webkit-transform: translate(0, -50%);
                        transform: rotate(135deg);
                        width: 8px;
                        height: 2px;
                        background-color: #000;
                    }
                }
            }

            &.active {
                background-color: #000;

                > a {
                    color: #fff;

                    &:before {
                        display: none;
                    }

                    &:after {
                        background-color: #fff;
                    }

                    > button {
                        &:before {
                            background-color: $mobilemenu-level2;
                            transform: rotate(45deg);
                        }
                        &:after {
                            background-color: $mobilemenu-level2;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            ul {
                background-color: $mobilemenu-level2;
                padding: 4px 0;

                > li {
                    padding: 10px 36px;

                    a {
                        color: #000;
                    }
                }
            }
        }
    }

    &--bottom {
        background-color: $sam-pink;
        font-size: calc-em(14px);
        line-height: 20px;
        color: #fff;
        padding: 13px 18px;
        display: inline-block;

        li {
            float: left;
            padding-right: 33px;
            display: inline-block;
        }

        a {
            display: inline-block;
            color: #fff;
            padding: 7px 0;
        }

        .bottomNav {
            li {
                width: 50%;
                padding-right: 16px;
            }
            .icon{
                padding-right: 5px;
            }
            .nav-ticket{
                img{
                    width: 20px;
                    left: 0px;
                    top: auto;
                }
            }
        }

        .social-nav .social-share {
            border-bottom: 0;
            margin-top: 1.6875em;
            margin-bottom: 1.1875em;
            padding: 0;
            background-color: #0000001a;
            display: inline-block;
        }

        .social-nav ul li {
            padding-right: 0;
            margin: 0 8px;
        }

        // .social-nav ul li:first-child {
        //     margin-left: 0;
        // }

        .social-nav ul li a::before {
            color: #fff;
        }
    }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
    .site-header__mobile {
        top: 88px;
    }
}
