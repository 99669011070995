// blog-banner styles
.blog-banner {
    position: relative;
    margin-bottom: 20px;

    &.margin-bottom--zero {
      margin-bottom: 0;
    }

    &__video{
        aspect-ratio: 1900 / 800;
        &.ratio-auto {
          aspect-ratio: auto;
        }
        video{
            display: block;
            object-fit: cover;
            object-position: center;
            min-height: 250px;
        }
    }

    &__image {
        @include aspect-ratio(1600, 800, 100%);
        padding-top: 40%;

        img {
            @include image-cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__text {
        text-align: center;
        max-width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;

        h1 {
            font-family: 'GT-Walsheim-Black';
            font-size: calc-em(70px);
            line-height: 1;
            margin: 0 0 22px;
        }

        p {
            font-size: calc-em(32px);
            line-height: 1;
        }
    }
}

@media only screen and (max-width: 47.9375em){
    .blog-banner {
        margin-bottom: 65px;

        &__image {
            padding-top: 0;
            height: calc(100vh - 88px);
        }

        &__text {
            h1 {
                font-size: calc-em(40px);
                margin: 0 0 12px;
            }

            p {
                font-size: calc-em(22px);
            }
        }
    }
}

@media only screen and (max-width: 39.9375em) {
    .blog-banner {
        &__image {
           /* height: calc(100vh - 56px);*/
           height: 0;
           padding-top:133%;
        }
    }
}

@media only screen and (max-width: 29.9375em) {
    .blog-banner {
        margin-bottom: 25px;

        &__text {
            h1 {
                font-size: calc-em(32px);
            }

            p {
                font-size: calc-em(16px);
            }
        }
    }
}

.banner-no-margin {
    .blog-banner {
        margin-bottom: 0;
    }
}
