// exhibition-banner styles
.exhibition-banner {
    position: relative;
    $p: &;

    &__media {
        position: relative;

        @include desktop {
            min-height: 585px;
        }
        
        picture, img {
            position: relative;
            display: block;
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: left center;
        }
    }

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        background: black;
        padding: 32px 20px;
        color: white;
        font-size: 16px;
        line-height: 1.5;
        gap: 4px;

        @include desktop {
            position: absolute;
            top: 50%;
            right: 5%;
            max-height: 90%;
            transform: translateY(-50%);
            overflow: auto;
            padding: 48px;
            max-width: 35%;
        }

        &--title {
            margin-bottom: 14px;

            h1 {
                font-size: 28px;
                line-height: 1.2;
                color: white;
                font-family: "GT-Walsheim-Black";
                margin: 0;
            }
        }

        &--date {
            color: $sam-yellow;
            font-size: 16px;
            line-height: 1.5;
        }

        &--location {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            gap: 8px;
            font-size: 12px;
            line-height: 1.5;
            color :white;
            margin-bottom: 16px;

            .icon {
                color: white;
                font-size: 12px;
            }
        }

        &--price {
            font-size: 12px;
            line-height: 1.5;
            color :white;
            margin-bottom: 12px;
        }

        &--cta {
            display: flex; 
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            gap: 10px;

            .btn {
                flex: 1;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 12px;
                font-size: 12px;
                line-height: 1.5;
                font-weight: bold;

                @include tablet {
                    font-size: 16px;
                    line-height: 1.5;
                }

                &--plan {
                    background: $sam-pink;
                    color: white;
                    border: 2px solid $sam-pink;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        border-color: $sam-red;
                        background: $sam-red;
                    }
                }

                &--guide {
                    background: transparent;
                    color: white;
                    border: 2px solid white;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        border-color: $btn-border;
                    }
                }
            }
        }
    }
}