// search-module styles
.search-result__form {
    max-width: none;
}

.search-result {
    margin-top: 120px;
}

.search__filters {
    // @extend %clearfix;
    margin: 80px -10px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .custom-select {
        // float: left;
        margin: 0 2.5%;
        min-width: 20%;
        max-width: 20%;
        font: 1.08em "GT-Walsheim-Black";
        height: 1.44676em;
        padding: 0 10px;

        &:after {
            content: '';
            width: 99%;
            border-bottom: 0.1875em solid #000000;
            display: block;
            position: absolute;
            left: 0;
            bottom: -0.46296em;
        }

        .overlay-text {
            top: 18px;
        }
    }

    .custom-select__options {
        top: 1.7284em;
        left: -0.06173em;
    }

    .custom-select__styled {
        padding: 0 1.75em 0 0.3125em;

        &:after {
            font-size: 0.6875em;
            right: 0.45455em;
        }
    }
}

.custom-select__styled {
    z-index: 1;
}

.custom-select__options {
    z-index: 2;
}

.search-filter-toggle {
    margin: 40px 0 0;
    text-align: right;


    button {
        border: none;
        background-color: transparent;
        color: #000;
        font-size: calc-em(16px);
        outline: 0;
        font-weight: 600;
        cursor: pointer;

        span {
            display: inline-block;
            margin-right: 6px;
        }

        i {
            position: relative;
            top: 1px;
            font-size: calc-em(14px, 16px);
            display: inline-block;
            transform: rotate(90deg);
            transition: all 0.3s;
        }

        &.active {
            i {
                transform: scaleX(-1) rotate(-90deg);
            }
        }
    }
}

@media only screen and (min-width: 1441px) and (max-width: 1919px) {
    .search__filters {
        .custom-select {
            .overlay-text {
                top: 14px;
            }
        }
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    .search__filters {
        .custom-select {
            .overlay-text {
                top: 12px;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .search__filters {
        .custom-select {
            .overlay-text {
                top: 9px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .search__filters {
        .custom-select {
            .overlay-text {
                top: 13px;
            }
        }
    }
}

@media only screen and (max-width: 767px){
    .search-result {
        margin-top: 60px;
    }

    .search__filters {
        margin: 50px -10px -50px;

        .custom-select {
            min-width: 50%;
            max-width: 50%;
            margin: 0 0 50px;
            padding: 0 10px;

            &:after {
                left: 10px;
                width: calc(100% - 20px);
            }
        }

        .custom-select__options {
            left: 10px;
            right: 10px;
        }

        .custom-select__styled {
            left: 10px;
            right: 10px;

            &:after {
                font-size: 0.6875em;
                right: 0.45455em;
            }
        }
    }
}

@media only screen and (max-width: 479px) {
    .search__filters {
        margin: 40px -10px -40px;

        .custom-select {
            min-width: 100%;
            max-width: 100%;
            margin: 0 0 40px;
        }
    }
}
