@font-face {
	font-family: 'icomoon';
	src:  url('/styles/assets/fonts/icomoon.eot??vnhxtk');
	src:  url('/styles/assets/fonts/icomoon.eot??vnhxtk#iefix') format('embedded-opentype'),
  url('/styles/assets/fonts/icomoon.ttf??vnhxtk') format('truetype'),
  url('/styles/assets/fonts/icomoon.woff??vnhxtk') format('woff'),
  url('/styles/assets/fonts/icomoon.svg??vnhxtk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: initial;


	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:before {
		font-family: 'icomoon' !important;
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		vertical-align: initial;

		/* Better Font Rendering =========== */
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}

%icomoon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'icomoon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	vertical-align: initial;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-tickets:before {
  content: "\e900";
}
.icon-shuttle:before {
  content: "\e901";
}
.icon-on-foot:before {
  content: "\e902";
}
.icon-mrt:before {
  content: "\e903";
}
.icon-car:before {
  content: "\e904";
}
.icon-google-arts-culture:before {
  content: "\e905";
}
.icon-spotify:before {
  content: "\e906";
}
.icon-trip-advisor:before {
  content: "\e907";
}
.icon-chevron-right:before {
  content: "\e908";
}
.icon-chat:before {
  content: "\e909";
}
.icon-clock:before {
  content: "\e90a";
}
.icon-search:before {
  content: "\e90b";
}
.icon-close:before {
  content: "\e90c";
}
.icon-chevron-left:before {
  content: "\e90d";
}
.icon-calendar:before {
  content: "\e90e";
}
.icon-download:before {
  content: "\e90f";
}
.icon-chevron-thin-right:before {
  content: "\e910";
}
.icon-chevron-thin-left:before {
  content: "\e911";
}
.icon-bus:before {
  content: "\e912";
}
.icon-tickets-circle:before {
  content: "\e913";
}
.icon-tickets-circle-reverse:before {
  content: "\e914";
}
.icon-tickets-circle-reverse-solid:before {
  content: "\e915";
}
.icon-ticket:before {
  content: "\e916";
}
.icon-location:before {
  content: "\e917";
}
.icon-time:before {
  content: "\e918";
}
.icon-calendar-dot:before {
  content: "\e919";
}
.icon-check:before {
  content: "\e91a";
}
.icon-chevron-down:before {
  content: "\e91b";
}
.icon-chevron-up:before {
  content: "\f106";
}
