// rte styles
.rte {
	// margin: 0 0 30px;

	> * {
		// font-size: calc-em(12px);
		// line-height: 16px;
		// margin: 0 0 16px;
	}

	h1, h2, h3, h4, h5, h6 {
		// color: $dnBlue;
	}

	h1 {
		// font-size: calc-em(23px);
		// line-height: 27px;
	}

	h2 {
		// font-size: calc-em(20px);
		// line-height: 24px;
	}

	h3 {
		// font-size: calc-em(18px);
		// line-height: 22px;
	}

	h4 {
		// font-size: calc-em(16px);
		// line-height: 20px;
	}

	h5 {
		// font-size: calc-em(14px);
		// line-height: 18px;
	}

	h6 {
		// font-size: calc-em(12px);
		// line-height: 16px;
	}

	a {
		// color: $dnBlue;
		// text-decoration: none;

		&:hover {
			// text-decoration: underline;
		}
	}

	table {
		border-collapse: collapse;
		border-right: 1px solid #000;
		border-left: 1px solid #000;

		th, td {
			padding: 10px;
		}

		th {
			// background-color: $dnBlue;
			// color: #fff;
		}

		td {
			border-bottom: 1px solid #000;
		}
	}
}

@media only screen and (min-width: $bpDesktop) {
	.rte {
		// margin: 0 0 50px;

		> * {
			// font-size: calc-em(15px);
			// line-height: 25px;
		}

		h1 {
			// font-size: calc-em(32px);
			// line-height: 36px;
		}

		h2 {
			// font-size: calc-em(28px);
			// line-height: 32px;
		}

		h3 {
			// font-size: calc-em(24px);
			// line-height: 28px;
		}

		h4 {
			// font-size: calc-em(20px);
			// line-height: 24px;
		}

		h5 {
			// font-size: calc-em(18px);
			// line-height: 22px;
		}

		h6 {
			// font-size: calc-em(16px);
			// line-height: 20px;
		}
	}
}
