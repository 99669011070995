// blog-filter styles
.blog-filter {
    width: 100%;
    margin-bottom: 35px;

    &__header {
        padding: 22px 20px;
        color: #fff;
        background-color: #000;
        position: relative;

        p {
            text-transform: uppercase;
            font-family: 'roboto-bold';
        }

        button {
            background: transparent;
            color: #fff;
            font-size: calc-em(13px);
            font-family: 'roboto-bold';
            position: absolute;
            bottom: 22px;
            right: 20px;
            border: none;
            outline: 0;
            cursor: pointer;
            text-decoration: underline;
        }
    }

    &__options {
        padding: 30px 20px 35px;
        margin-bottom: 12px;
        background-color: #f5f5f5;
    }

    .option-category {
        p {
            padding: 0 0 12px;
            border-bottom: 1px solid #000;
            font-family: 'roboto-medium';
            margin-bottom: 24px;
        }
    }

    .options {
        button {
            font-family: 'roboto-black';
            padding-top: 13px;
            padding-bottom: 13px;
            text-align: center;
            border: 1px solid #000;
            width: 100%;
            background-color: transparent;
            cursor: pointer;
            outline: 0;
            text-transform: uppercase;
        }
    }
}

@media only screen and (max-width: 63.9375em) {
    .blog-filter {
        padding: 0 9px;
    }
}
