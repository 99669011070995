// associated-events styles

.associated-events {
    .more-resource--special .more-resource__text {
        margin-top: 20px;
    }

    .more-resource--special {
    
        .more-resource__title {
            font-size: calc-em(20px);
            margin-top: 15px;
        }
    }

    .more-resource--special .list-tagline {
        margin-top: 15px;
    }

    .more-resource__info {
        display: inline-block;
        margin-top:25px;
        li {
            padding-bottom: 3px;
    
            img {
                width:20px;
                height: 20px;
                display: inline-block;
                margin-right: 12px;
            }
    
            span {
                display: inline-flex;
                align-items: flex-start;
                width: 100%;
            }

            &:first-child span {
                align-items: center;
            }

            ul li {
                padding: 0;
            }
        }
    
        .more-resource__date, .card__date {
            font-size: calc-em(16px);
            font-weight: bold;
            color: black;
            font-family: 'GT-Walsheim-Black';
            padding: 0;
        }
    }

    .more-resource--special .more-resource__image:after, .more-resource--special .more-resource__image:before {
        display: none;
    }
    
}

.associated-events--list-view {
    .more-resource__info {
        display: block;
    }
}

@media only screen and (max-width: 1023px){
    .associated-events {
        .more-resource__info {
            margin-top: 15px;
            li img {
                width: 16px;
                height: 16px;
            }
        }

        .more-resource--special .list-tagline {
            margin-top: 10px;
        }

        .more-resource--special .more-resource__text {
            margin-top: 10px;
        }

        .more-resource--special .more-resource__title {
            font-size: calc-em(22px);
        }
        
    }
}