// intro styles
.intro {
  $p: &;

  &-content {
    margin: 0 0 4em;

    #{$p}-title {
      font-family: "GT-Walsheim-Black";
      font-size: 3.36125em;
      color: #000000;
      letter-spacing: -.19px;
      text-align: center;
      margin: 0 0 25px;
    }

    #{$p}-text {
      .rte {
        padding: 0 5em;

        @include mobile {
          padding: 0 2em;
        }
      }
    }

    #{$p}-cta {
      text-align: center;
      display: block;
      margin: 2em 0;

      .btn {
        &__text {
          padding: 0.8125em 2em;
        }
      }
    }
  }
}