// exhibition-anchor styles
.exhibition-anchor {
    $p: &;
    position: relative;
    line-height: 1.5;
    margin: 28px 0;

    @include desktop {
        font-size: 20px;
        margin: 40px 0 80px;
    }

    &__nav {
        position: relative;
        width: 100%;
        background: white;
        transition: all 0.3s ease-in-out;

        @include desktop {
            width: 1440px;
            max-width: 100%;
            margin: 0 auto;
            padding: 0 104px;
        }

        &__dropdown {
            button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 14px 20px;
            background: $sam-white2;
            font-size: 16px;
            line-height: 1.4;
            font-weight: bold;
            border: none;
            margin: 0;
            outline: 0;

                .icon {
                    font-size: 23px;
                }
            }
        }

        &__list {
            position: absolute;
            display: flex;
            flex-direction: column;
            left: 0;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            max-height: 0;
            width: 100%;
            background: $sam-white2;
            z-index: 3;
            transition: all 0.3s ease-in-out;

            @include desktop {
                position: relative;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                background: transparent;
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                max-height: unset;
                border-bottom: 1px solid $search-border;
            }

            li {
                list-style-type: none;

                a{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 14px 20px;
                    font-size: 16px;
                    line-height: 1.4;
                    color: black;
                    border-bottom: 2px solid transparent;
                    transition: all 0.3s ease-in-out;

                    @include desktop {
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 1.4;
                    }

                    .icon {
                        color: white;
                        opacity: 0;
                        visibility: hidden;
                    }

                    &.active {
                        color: white;
                        background: $sam-pink;
                        
                        .icon {
                            opacity: 1;
                            visibility: visible;
                        }

                        @include desktop {
                            background: transparent;
                            color: $sam-pink;
                            border-bottom: 2px solid $sam-pink;
                        }
                    }

                    &:hover {
                        @include desktop {
                            background: transparent;
                            color: $sam-pink;
                            border-bottom: 2px solid $sam-pink;
                        }
                    }
                }
            }
        }

        &.expanded {
            #{$p}__nav__dropdown {
                border-bottom: 1px solid black;

                .icon {
                    transform: rotate(180deg)
                }
            }

            #{$p}__nav__list {
                opacity: 1;
                visibility: visible;
                pointer-events: auto;
                max-height: unset;
            }
        }

        &.sticky {
            position:sticky;
            z-index: 2;

            @include desktop {
                display: flex;
                justify-content: center;
                align-items: center;
                width: unset;
                margin: unset;
                padding: 0 32px;
                border-bottom: 1px solid $search-border;

                #{$p}__nav__list {
                    padding-top: 12px;
                    border-bottom: none;
                }
            }
        }
    }

    &__content {
        position: relative;
        border-bottom: 1px solid black;
        margin: 28px 0;

        @include desktop {
            flex-direction: row-reverse;
            border-bottom: none;
            margin: 40px 0;
        }

        > .container {
            padding: 0;

            @include desktop {
                width: 1440px;
            }
        }

        &__wrapper {
            display: flex;
            flex-direction: column;

            @include desktop {
                flex-direction: row-reverse;
                margin: 0 80px;
                border-bottom: 1px solid black;
                gap: 104px;
            }
        }

        &__info {
            font-size: 14px;
            line-height: 1.5;
            padding: 0 20px 28px;
            border-bottom: 1px solid black;

            @include desktop {
                flex: 1 1 30%;
                border-bottom: none;
            }

            &__wrapper {
                &.sticky {

                    @include desktop {
                        position: sticky;
                    }
                }
            }

            &--date, &--time, &--location, &--price, &--item {
                display: flex; 
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 18px 0;
                font-weight: 700;
                border-bottom: 1px solid black;

                &:first-child {
                    padding-top: 0;
                }

                &:last-child {
                    border-bottom: none;
                }

                .main {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                }
            }
            &--cta {
                display: flex; 
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                gap: 12px;

                .btn {
                    flex: 1;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    padding: 12px;
                    font-size: 12px;
                    line-height: 1.5;
                    font-weight: bold;
                    box-shadow:0 4 4 0 rgba (black, 0.25);

                    @include desktop {
                        font-size: 16px;
                        line-height: 1.5;
                    }

                    &--plan {
                        background: $sam-pink;
                        color: white;
                        border: 2px solid $sam-pink;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            border-color: $sam-red;
                            background: $sam-red;
                        }
                    }

                    &--guide {
                        background: white;
                        color: black;
                        border: 2px solid black;
                        transition: all 0.3s ease-in-out;

                        &:hover {
                            border-color: $btn-border;
                        }
                    }
                }
            }
        }

        &__detail {
            padding: 28px 20px 0;

            @include desktop {
                padding: 0 20px;
                flex: 1 1 70%;
            }

            h1, h2, h3, h4, h5, h6 {
                font-family: 'GT-Walsheim-Black';
                margin-bottom: 12px;

                @include desktop {
                    margin-bottom: 16px;
                }
            }

            h2 {
                font-size: 28px;
                line-height: 1.4
            }

            h3 {
                font-size: 20px;
                line-height: 1.5;
            }

            &--item {
                padding: 28px 0;
                border-bottom: 1px solid black;

                &:first-child {
                    padding: 0 0 28px;
                }

                &:last-child {
                    border-bottom: none;
                }

                @include desktop {
                    padding: 40px 0;
                }

                > p {
                    font-size: 14px;
                }

                .caption {
                    position: relative;
                    font-size: 14px;
                    line-height: 14px;
                    padding-top: 20px;
                    margin-top: 20px;

                    @include desktop {
                        padding-top: 30px;
                        padding-top: 20px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 80px;
                        height: 1px;
                        background: black;
                    }
                }

                &--download {
                    font-size: 14px;

                    @include desktop {
                        font-size: 18px;
                    }

                    ul {
                        float: unset; 
                        max-width: unset;

                        li {
                            margin-bottom: 10px;

                            @include desktop {
                                margin-bottom: 12px;
                            }
                        }

                        .download-list__ico {
                            font-size: 30px;

                            @include desktop {
                                font-size: 36px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.exhibition-anchor__content {
    .selected-artwork {
        > .container {
            padding: 0;

            .col-4 {
                width: 50%;
            }

            .title__wrapper {
                text-align: start;
            }

            .row {
                justify-content: normal;
            }
        }
    }
}