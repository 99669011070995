// collection styles
.collection {
    &__image {
        @include aspect-ratio(443px, 333px, 100%);

        img {
            @include image-cover;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

@media screen and (max-width: 47.9375em){
    .collection .btn--cta {
        max-width: 100%;
    }
}
